import { Link, useLocation } from "react-router-dom";

export default function Header() {
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <div className="middle-sidebar-header bg-white">
      <button className="header-menu" />
      <form action="#" className="float-left header-search">
        <div className="form-group mb-0 icon-input">
          <i className="feather-search font-lg text-grey-400" />
          <input
            type="text"
            placeholder="Search here"
            className="bg-transparent border-0 lh-32 pt-2 pb-2 pl-5 pr-3 font-xsss fw-500 rounded-xl w350"
          />
        </div>
      </form>
      <ul className="d-flex ml-auto right-menu-icon">
        <li>
          <Link>
            <span className="dot-count bg-warning" />
            <i className="feather-bell font-xl text-current" />
            <div className="menu-dropdown">
              <h4 className="fw-700 font-xs mb-4">Notification</h4>
              <div className="card bg-transparent-card w-100 border-0 pl-5 mb-3">
                <img
                  src="/images/user-8.png"
                  alt="user"
                  className="w40 position-absolute left-0"
                />
                <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">
                  Katherine Mueller
                  <span className="text-grey-400 font-xsssss fw-600 float-right mt-1">
                    3 min
                  </span>
                </h5>
                <h6 className="text-grey-500 fw-500 font-xssss lh-4">
                  There are many variations of pass..
                </h6>
              </div>
              <div className="card bg-transparent-card w-100 border-0 pl-5 mb-3">
                <img
                  src="/images/user-4.png"
                  alt="user"
                  className="w40 position-absolute left-0"
                />
                <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">
                  Mohammed Kunde
                  <span className="text-grey-400 font-xsssss fw-600 float-right mt-1">
                    2 min
                  </span>
                </h5>
                <h6 className="text-grey-500 fw-500 font-xssss lh-4">
                  Mobile Apps UI Designer is require..
                </h6>
              </div>
              <div className="card bg-transparent-card w-100 border-0 pl-5 mb-3">
                <img
                  src="/images/user-7.png"
                  alt="user"
                  className="w40 position-absolute left-0"
                />
                <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">
                  Derick Hirthe
                  <span className="text-grey-400 font-xsssss fw-600 float-right mt-1">
                    1 min
                  </span>
                </h5>
                <h6 className="text-grey-500 fw-500 font-xssss lh-4">
                  Mobile Apps UI Designer is require..
                </h6>
              </div>
              <div className="card bg-transparent-card w-100 border-0 pl-5">
                <img
                  src="/images/user-6.png"
                  alt="user"
                  className="w40 position-absolute left-0"
                />
                <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">
                  Madeline Ratke
                  <span className="text-grey-400 font-xsssss fw-600 float-right mt-1">
                    30 sec
                  </span>
                </h5>
                <h6 className="text-grey-500 fw-500 font-xssss lh-4">
                  Mobile Apps UI Designer is require..
                </h6>
              </div>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/dashboard/messages">
            <i className="feather-message-square font-xl text-current" />
          </Link>
        </li>
        <li>
          <a href={location.pathname}>
            <i className="feather-settings animation-spin d-inline-block font-xl text-current" />
            <div className="menu-dropdown switchcolor-wrap">
              <h4 className="fw-700 font-xs mb-4">Settings</h4>
              <div className="card bg-transparent-card border-0 d-block mt-3">
                <h4 className="d-inline font-xssss mont-font fw-700">
                  Dark Mode
                </h4>
                <div className="d-inline float-right mt-1">
                  <label className="toggle toggle-dark">
                    <input type="checkbox" />
                    <span className="toggle-icon" />
                  </label>
                </div>
              </div>
            </div>
          </a>
        </li>
        <li>
          <Link to="/dashboard/settings">
            <img src={user.image} alt="Profile" className="w40 mt--1" />
          </Link>
        </li>
        <li>
          <Link className="menu-search-icon">
            <i className="feather-search text-grey-900 font-lg" />
          </Link>
        </li>
      </ul>
    </div>
  );
}
