import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const grades = [
  { value: "A+", label: "A+" },
  { value: "A", label: "A" },
  { value: "A-", label: "A-" },
  { value: "B+", label: "B+" },
  { value: "B", label: "B" },
  { value: "B-", label: "B-" },
  { value: "C+", label: "C+" },
  { value: "C", label: "C" },
  { value: "C-", label: "C-" },
  { value: "D+", label: "D+" },
  { value: "D", label: "D" },
  { value: "D-", label: "D-" },
  { value: "F", label: "F" },
];

function GradeStudents() {
  const navigate = useNavigate();

  const [selectedGrade, setSelectedGrade] = useState([]);

  const handleGradeSelection = (selectedValues) => {
    setSelectedGrade(selectedValues);
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
          Grade Students
        </h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form action="#">
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Course ID</label>
                <input
                  type="text"
                  name="comment-name"
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Course Name
                </label>
                <input
                  type="text"
                  name="comment-name"
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
              <Link className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block">
                Filter Students
              </Link>
            </div>
          </div>
        </form>
      </div>
      <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
        <table className="table table-bordered data-table">
          <thead>
            <tr>
              <th className="bg-current text-white">Student ID</th>
              <th className="bg-current text-white">Student Name</th>
              <th className="bg-current text-white">Course</th>
              <th className="bg-current text-white">Assign Grade</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>John Doe</td>
              <td>Algorithms</td>
              <td className="text-center">
                <select className="form-control" name="grades" id="grades">
                  <option value="">Grade</option>
                  {grades.map((grade) => (
                    <option
                      key={grade.value}
                      value={grade.value}
                      onClick={handleGradeSelection}
                      selected={grade.value === selectedGrade}
                    >
                      {grade.label}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default GradeStudents;
