import { Link } from "react-router-dom";

export default function Sidebar() {
  const username = localStorage.getItem("name");
  return (
    <div className="middle-sidebar-right right-scroll-bar">
      <div className="middle-sidebar-right-content">
        <div className="card overflow-hidden subscribe-widget p-3 mb-3 rounded-xxl border-0">
          <div
            className="card-body p-2 d-block text-center bg-no-repeat bg-image-topcenter"
            style={{ backgroundImage: "url(/images/user-pattern.png)" }}
          >
            <figure className="avatar ml-auto mr-auto mb-0 mt-2 w90">
              <img
                src="/images/user-12.png"
                alt="user-12"
                className="float-right shadow-sm rounded-circle w-100"
              />
            </figure>
            <div className="clearfix" />
            <h2 className="text-black font-xss lh-3 fw-700 mt-3 mb-1">
              {username}
            </h2>
            <h4 className="text-grey-500 font-xssss mt-0">
              <span className="d-inline-block bg-success btn-round-xss m-0" />{" "}
              Online
            </h4>
            <div className="clearfix" />
            <div className="col-12 pl-0 mt-4 text-left">
              <div className="carousel-card owl-carousel owl-theme overflow-visible nav-none">
                <div className="item">
                  <Link className="btn-round-xxxl border bg-greylight">
                    <img
                      src="/images/download1.png"
                      alt="icon"
                      className="p-3"
                    />
                  </Link>
                </div>
                <div className="item">
                  <Link className="btn-round-xxxl border bg-greylight">
                    <img
                      src="/images/download2.png"
                      alt="icon"
                      className="p-3"
                    />
                  </Link>
                </div>
                <div className="item">
                  <Link className="btn-round-xxxl border bg-greylight">
                    <img
                      src="/images/download4.png"
                      alt="icon"
                      className="p-3"
                    />
                  </Link>
                </div>
                <div className="item">
                  <Link className="btn-round-xxxl border bg-greylight">
                    <img
                      src="/images/download3.png"
                      alt="icon"
                      className="p-3"
                    />
                  </Link>
                </div>
                <div className="item">
                  <Link className="btn-round-xxxl border bg-greylight">
                    <img
                      src="/images/download1.png"
                      alt="icon"
                      className="p-3"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card overflow-hidden subscribe-widget p-3 mb-3 rounded-xxl border-0">
          <div className="card-body d-block text-left">
            <h1 className="text-grey-800 font-xl fw-900 mb-4 lh-3">
              Sign up for our newsletter
            </h1>
            <form action="#" className="mt-3">
              <div className="form-group icon-input">
                <i className="ti-email text-grey-500 font-sm" />
                <input
                  type="text"
                  className="form-control mb-2 bg-greylight border-0 style1-input pl-5"
                  placeholder="Enail address"
                />
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="blankCheckbox"
                  defaultValue="option1"
                />
                <label
                  className="text-grey-500 font-xssss"
                  htmlFor="blankCheckbox"
                >
                  By checking this box, you confirm that you have read and are
                  agreeing to our terms of use regarding.
                </label>
              </div>
            </form>
            <ul className="d-flex align-items-center justify-content-between mt-3">
              <li>
                <Link className="btn-round-md bg-facebook">
                  <i className="font-xs ti-facebook text-white" />
                </Link>
              </li>
              <li>
                <Link className="btn-round-md bg-twiiter">
                  <i className="font-xs ti-twitter-alt text-white" />
                </Link>
              </li>
              <li>
                <Link className="btn-round-md bg-linkedin">
                  <i className="font-xs ti-linkedin text-white" />
                </Link>
              </li>
              <li>
                <Link className="btn-round-md bg-instagram">
                  <i className="font-xs ti-instagram text-white" />
                </Link>
              </li>
              <li>
                <Link className="btn-round-md bg-pinterest">
                  <i className="font-xs ti-pinterest text-white" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
