import { Link } from "react-router-dom";
import { useState } from "react";
import env from "../../../../env";
import { toast } from "react-toastify";
import axios from "axios";

export default function AccountInformation() {
  const baseUrl = env.BASE_URL;
  const token = localStorage.getItem("token");
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("name", user.name);
    formData.append("email", user.email);
    formData.append("phone", user.phone);
    formData.append("role", user.role);
    formData.append("address", user.address);

    if (user.image instanceof File) {
      formData.append("image", user.image);
    }

    axios
      .post(baseUrl + "/user/" + user.id, formData, config)
      .then((response) => {
        localStorage.setItem("user", JSON.stringify(response.data));
        toast.success("User Updated Successfully!");
        setUser(response.data);
      })
      .catch(function (error) {
        const tempdata = error.response.data;

        if (tempdata.errors) {
          const errorKeys = Object.keys(tempdata.errors);
          for (const errorKey of errorKeys) {
            for (const error of tempdata.errors[errorKey]) {
              toast.error(`${error}`);
            }
          }
        }
      });
  };

  return (
    <div>
      <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-lg">
        <Link to="/dashboard/settings" className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
          Account Details
        </h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0">
        <div className="row justify-content-center">
          <div className="col-lg-4 text-center">
            <figure className="avatar ml-auto mr-auto mb-0 mt-2 w100">
              <img
                src={
                  user.image instanceof File
                    ? URL.createObjectURL(user.image)
                    : user.image
                }
                alt="Profile"
                className="shadow-sm rounded-lg w-100"
              />
            </figure>
            <h2 className="fw-700 font-sm text-grey-900 mt-3">{user.name}</h2>
          </div>
        </div>
        <form action="#">
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Name</label>
                <input
                  type="text"
                  onChange={(e) => setUser({ ...user, name: e.target.value })}
                  className="form-control"
                  value={user.name}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Email</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                  value={user.email}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Phone</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setUser({ ...user, phone: e.target.value })}
                  value={user.phone}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Role</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setUser({ ...user, role: e.target.value })}
                  value={user.role}
                  disabled
                  style={{ textTransform: "capitalize" }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Address</label>
                <textarea
                  className="form-control"
                  onChange={(e) =>
                    setUser({ ...user, address: e.target.value })
                  }
                  value={user.address}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-3">
              <div className="card mt-3 border-0">
                <div className="card-body d-flex justify-content-between align-items-end p-0">
                  <div className="form-group mb-0 w-100">
                    <label className="mont-font fw-600 font-xsss">
                      Profile Picture (upload only if you want to change)
                    </label>
                    <input
                      type="file"
                      name="file"
                      id="file"
                      className="input-file"
                      onChange={(e) =>
                        setUser({ ...user, image: e.target.files[0] })
                      }
                    />
                    <label
                      htmlFor="file"
                      className="rounded-lg text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                    >
                      <i className="ti-cloud-down large-icon mr-3 d-block" />
                      <span className="js-fileName">
                        Upload an Image
                        <br />
                        (Supported Formats : jpeg,png,jpg,gif,svg | File size{" "}
                        {"<="} 2MB)
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <Link
                onClick={handleSubmit}
                className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block"
              >
                Save
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
