import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";

function AssignStudents() {
  const navigate = useNavigate();
  const [instructorName, setInstructorName] = useState("");
  const [instructorIds] = useState([
    { value: "I01", label: "I01" },
    { value: "I02", label: "I02" },
    { value: "I03", label: "I03" },
    { value: "I04", label: "I04" },
  ]);
  const [courses] = useState([
    { value: "Operating Systems", label: "Operating Systems" },
    { value: "Networking", label: "Networking" },
    { value: "Algorithms", label: "Algorithms" },
    { value: "Compiler Design", label: "Compiler Design" },
    { value: "System Design", label: "System Design" },
  ]);

  const [students] = useState([
    { value: "Chad Nicolas", label: "Chad Nicolas" },
    { value: "Noah Buckridge", label: "Noah Buckridge" },
    { value: "Sherman Herman", label: "Sherman Herman" },
    { value: "David Durgan", label: "David Durgan" },
    { value: "Hannah Dach", label: "Hannah Dach" },
    { value: "Jesus Hermiston", label: "Jesus Hermiston" },
    { value: "Douglas Hayes PhD", label: "Douglas Hayes PhD" },
    { value: "Merle Gerlach", label: "Merle Gerlach" },
  ]);
  const [selectedInstructorIds, setSelectedInstructorIds] = useState("");
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  let isError = false;
  const [mode, setMode] = useState("assign");
  const [activeId, setActiveId] = useState(-1);
  const [instructors, setInstructors] = useState([]);

  const handleCourseSelection = (selectedValues) => {
    setSelectedCourses(selectedValues);
  };
  const handleIdSelection = (value) => {
    setSelectedInstructorIds(value);
  };

  const handleStudentSelection = (selectedValues) => {
    setSelectedStudents(selectedValues);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let fieldName = [];
    if (!instructorName.length) {
      fieldName.push("Instructor Name");
    }
    if (!selectedInstructorIds) {
      fieldName.push("Instructor ID");
    }
    if (selectedCourses.length === 0) {
      fieldName.push("Selected Courses");
    }
    if (selectedStudents.length === 0) {
      fieldName.push("Selected Students");
    }
    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }
    if (isError) {
      return;
    }
    if (mode === "assign") {
      assignStudentCourse();
    } else {
      updateAssignedCourse();
    }
  };
  const clearFields = () => {
    setInstructorName("");
    setSelectedInstructorIds("");
    setSelectedCourses([]);
    setSelectedStudents([]);
  };
  const assignStudentCourse = () => {
    const newInstructor = {
      id: selectedInstructorIds,
      instructorName: instructorName,
      selectedCourses: selectedCourses,
      selectedStudents: selectedStudents,
    };
    setInstructors((prevInstructors) => [...prevInstructors, newInstructor]);
    clearFields();
  };
  const editAssignCourse = (id) => {
    setMode("edit");
    setActiveId(id);
    const instructor = instructors.find((instructor) => instructor.id === id);
    setInstructorName(instructor.instructorName);
    setSelectedInstructorIds(instructor.id);
    setSelectedCourses(instructor.selectedCourses);
    setSelectedStudents(instructor.selectedStudents);
  };
  const deleteAssignedCourse = (id) => {
    const newInstructors = instructors.filter(
      (instructor) => instructor.id !== id
    );
    setInstructors(newInstructors);
    clearFields();
  };
  const updateAssignedCourse = () => {
    const instructor = instructors.find(
      (instructor) => instructor.id === activeId
    );
    instructor.instructorName = instructorName;
    instructor.selectedCourses = selectedCourses;
    instructor.selectedStudents = selectedStudents;
    clearFields();
    setMode("assign");
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
          Assign Students
        </h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form action="#">
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Instructor ID
                </label>
                <Select
                  options={instructorIds}
                  value={selectedInstructorIds}
                  onChange={handleIdSelection}
                  placeholder="Select Instructor ID"
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Instructor Name
                </label>
                <input
                  type="text"
                  name="instructorName"
                  className="form-control"
                  onChange={(e) => setInstructorName(e.target.value)}
                  value={instructorName}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Courses</label>
                <Select
                  isMulti
                  options={courses}
                  value={selectedCourses}
                  onChange={handleCourseSelection}
                  placeholder="Select Courses"
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Students</label>
                <Select
                  isMulti
                  options={students}
                  value={selectedStudents}
                  onChange={handleStudentSelection}
                  placeholder="Select Students"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
              <Link
                className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block"
                onClick={(e) => handleSubmit(e)}
              >
                {mode === "assign" ? "Assign" : "Edit"} Students
              </Link>
            </div>
          </div>
        </form>
      </div>
      {instructors.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th className="bg-current text-white">Instructor ID</th>
                <th className="bg-current text-white">Instructor Name</th>
                <th className="bg-current text-white">Course(s)</th>
                <th className="bg-current text-white">Students</th>
                <th className="bg-current text-white">Actions</th>
              </tr>
            </thead>
            <tbody>
              {instructors.map((instructor) => (
                <tr key={instructor.id?.value}>
                  <td>{instructor.id?.value}</td>
                  <td>{instructor.instructorName}</td>
                  <td>
                    {instructor.selectedCourses
                      .map((course) => course.label)
                      .join(", ")}
                  </td>
                  <td>
                    {instructor.selectedStudents
                      .map((student) => student.label)
                      .join(", ")}
                  </td>
                  <td className="text-center">
                    <i
                      className="feather-edit text-current mr-sm-2 mr-md-3 mr-lg-4"
                      style={{ cursor: "pointer" }}
                      onClick={() => editAssignCourse(instructor.id)}
                    />
                    <i
                      className="feather-trash text-red"
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteAssignedCourse(instructor.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default AssignStudents;
