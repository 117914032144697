import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function FacultyDevelopment() {
  const navigate = useNavigate();
  let isError = false;

  const [mode, setMode] = useState("add");
  const [id, setId] = useState(1);
  const [activeId, setActiveId] = useState(-1);
  const [instructors, setInstructors] = useState([]);
  const [instructorName, setInstructorName] = useState("");
  const [skillsToDevelop, setSkillsToDevelop] = useState("");
  const [statusOptions] = useState([
    { value: "Todo", label: "Todo" },
    { value: "In Progress", label: "In Progress" },
    { value: "Done", label: "Done" },
  ]);
  const [selectedStatus, setSelectedStatus] = useState("");

  const handleStatusSelection = (e) => {
    setSelectedStatus(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let fieldName = [];
    if (!instructorName) {
      fieldName.push("Instructor Name");
    }
    if (!skillsToDevelop) {
      fieldName.push("SkillsToDevelop");
    }
    if (!selectedStatus) {
      fieldName.push("Selected Status");
    }
    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }
    if (isError) {
      return;
    }
    if (mode === "add") {
      addInstructor();
    } else {
      updateInstructor();
    }
  };

  const addInstructor = () => {
    const newInstrutor = {
      id: id,
      instructorName: instructorName,
      skillsToDevelop: skillsToDevelop,
      selectedStatus: selectedStatus,
    };
    console.log(newInstrutor);
    setInstructors((prevInstructors) => [...prevInstructors, newInstrutor]);
    clearFields();
    setId(id + 1);
  };

  const clearFields = () => {
    setInstructorName("");
    setSkillsToDevelop("");
    setSelectedStatus("");
  };

  const editInstructor = (id) => {
    setMode("edit");
    setActiveId(id);
    const instructor = instructors.find((instructor) => instructor.id === id);
    setInstructorName(instructor.instructorName);
    setSkillsToDevelop(instructor.skillsToDevelop);
    setSelectedStatus(instructor.selectedStatus);
  };

  const deleteInstructor = (id) => {
    const newInstructors = instructors.filter(
      (instructor) => instructor.id !== id
    );
    setInstructors(newInstructors);
  };

  const updateInstructor = () => {
    const instructor = instructors.find(
      (instructor) => instructor.id === activeId
    );
    instructor.instructorName = instructorName;
    instructor.skillsToDevelop = skillsToDevelop;
    instructor.selectedStatus = selectedStatus;
    clearFields();
    setMode("add");
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
          Faculty Development
        </h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form action="#">
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Instructor Name
                </label>
                <input
                  type="text"
                  name="instructor-name"
                  className="form-control"
                  value={instructorName}
                  onChange={(e) => setInstructorName(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Skills to Develop
                </label>
                <input
                  type="text"
                  name="Skills to Develop"
                  className="form-control"
                  value={skillsToDevelop}
                  onChange={(e) => setSkillsToDevelop(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <select
                  className="form-control"
                  name="status"
                  id="status"
                  value={selectedStatus}
                  onChange={handleStatusSelection}
                >
                  <option value="">Status</option>
                  {statusOptions.map((status) => (
                    <option key={status.value} value={status.value}>
                      {status.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-6 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
              <Link
                className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block"
                onClick={handleSubmit}
              >
                {mode === "add" ? "Add" : "Edit"} Instructor
              </Link>
            </div>
          </div>
        </form>
      </div>
      {instructors.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th className="bg-current text-white">Instructor ID</th>
                <th className="bg-current text-white">Instructor Name</th>
                <th className="bg-current text-white">Skills to Develop</th>
                <th className="bg-current text-white">Status</th>
                <th className="bg-current text-white">Actions</th>
              </tr>
            </thead>
            <tbody>
              {instructors.map((instructor) => (
                <tr key={instructor.id}>
                  <td>{instructor.id}</td>
                  <td>{instructor.instructorName}</td>
                  <td>{instructor.skillsToDevelop}</td>
                  <td>{instructor.selectedStatus}</td>
                  <td className="text-center">
                    <i
                      className="feather-edit text-current mr-sm-2 mr-md-3 mr-lg-4"
                      style={{ cursor: "pointer" }}
                      onClick={() => editInstructor(instructor.id)}
                    />
                    <i
                      className="feather-trash text-red"
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteInstructor(instructor.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default FacultyDevelopment;
