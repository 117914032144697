import { Link } from "react-router-dom";

function ManageCoordinator() {
  return (
    <div className="row">
      <div className="col-xl-4 col-lg-6 col-md-6">
        <div className="card mb-4 d-block w-100 shadow-xss rounded-lg p-xxl-5 p-4 border-0 text-center">
          <Link
            to="/dashboard/coordinator/program-implementation"
            className="p-2 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current"
          >
            Program Implementation
          </Link>
          <Link
            to="/dashboard/coordinator/support-and-enquiries"
            className="p-2 mt-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current"
          >
            Support & Enquiries
          </Link>
          <Link
            to="/dashboard/coordinator/student-performance-data"
            className="p-2 mt-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current"
          >
            Student Performance Data
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ManageCoordinator;
