import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
function ImprovementInitiatives() {
  const navigate = useNavigate();
  let isError = false;

  const [mode, setMode] = useState("add");
  const [id, setId] = useState(1);
  const [activeId, setActiveId] = useState(-1);
  const [initiatives, setInitiatives] = useState([]);
  const [improvementName, setImprovementName] = useState("");
  const [initiatedBy, setInitiatedBy] = useState("");
  const [statusOptions] = useState([
    { value: "Todo", label: "Todo" },
    { value: "In Progress", label: "In Progress" },
    { value: "Done", label: "Done" },
  ]);
  const [selectedStatus, setSelectedStatus] = useState("");

  const handleStatusSelection = (e) => {
    setSelectedStatus(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let fieldName = [];
    if (!improvementName) {
      fieldName.push("Improvement Name");
    }
    if (!initiatedBy) {
      fieldName.push("Initiated By");
    }
    if (!selectedStatus) {
      fieldName.push("Selected Status");
    }
    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }
    if (isError) {
      return;
    }
    if (mode === "add") {
      addInitiative();
    } else {
      updateIntiative();
    }
  };

  const addInitiative = () => {
    const newInitiative = {
      id: id,
      improvementName: improvementName,
      initiatedBy: initiatedBy,
      selectedStatus: selectedStatus,
    };
    setInitiatives((prevInitiatives) => [...prevInitiatives, newInitiative]);
    clearFields();
    setId(id + 1);
  };

  const clearFields = () => {
    setImprovementName("");
    setInitiatedBy("");
    setSelectedStatus("");
  };

  const editInitiative = (id) => {
    setMode("edit");
    setActiveId(id);
    const initiative = initiatives.find((initiative) => initiative.id === id);
    setImprovementName(initiative.improvementName);
    setInitiatedBy(initiative.initiatedBy);
    setSelectedStatus(initiative.selectedStatus);
  };

  const deleteInitiative = (id) => {
    const newInitiatives = initiatives.filter(
      (initiative) => initiative.id !== id
    );
    setInitiatives(newInitiatives);
  };

  const updateIntiative = () => {
    const initiative = initiatives.find(
      (initiative) => initiative.id === activeId
    );
    initiative.improvementName = improvementName;
    initiative.initiatedBy = initiatedBy;
    initiative.selectedStatus = selectedStatus;
    clearFields();
    setMode("add");
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
          Improvement Initiatives
        </h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form action="#">
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Improvement Name
                </label>
                <input
                  type="text"
                  name="improvement-name"
                  className="form-control"
                  value={improvementName}
                  onChange={(e) => setImprovementName(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Initiated by
                </label>
                <input
                  type="text"
                  name="Initiated-by"
                  className="form-control"
                  value={initiatedBy}
                  onChange={(e) => setInitiatedBy(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <select
                  className="form-control"
                  name="status"
                  id="status"
                  value={selectedStatus}
                  onChange={handleStatusSelection}
                >
                  <option value="">Status</option>
                  {statusOptions.map((status) => (
                    <option key={status.value} value={status.value}>
                      {status.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-6 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
              <Link
                className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block"
                onClick={handleSubmit}
              >
                {mode === "add" ? "Add" : "Edit"} Initiative
              </Link>
            </div>
          </div>
        </form>
      </div>
      {initiatives.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th className="bg-current text-white">Improvement ID</th>
                <th className="bg-current text-white">Improvement Name</th>
                <th className="bg-current text-white">Initiated By</th>
                <th className="bg-current text-white">Status</th>
                <th className="bg-current text-white">Actions</th>
              </tr>
            </thead>
            <tbody>
              {initiatives.map((initiative) => (
                <tr key={initiative.id}>
                  <td>{initiative.id}</td>
                  <td>{initiative.improvementName}</td>
                  <td>{initiative.initiatedBy}</td>
                  <td>{initiative.selectedStatus}</td>
                  <td className="text-center">
                    <i
                      className="feather-edit text-current mr-sm-2 mr-md-3 mr-lg-4"
                      style={{ cursor: "pointer" }}
                      onClick={() => editInitiative(initiative.id)}
                    />
                    <i
                      className="feather-trash text-red"
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteInitiative(initiative.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default ImprovementInitiatives;
