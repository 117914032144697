import { Link } from "react-router-dom";

export default function Livestream() {
  return (
    <div className="middle-sidebar-left" style={{ height: "88vh" }}>
      <div className="row">
        <div className="col-xl-8 col-xxl-9">
          <div
            className="card border-0 mb-0 rounded-lg overflow-hidden live-stream bg-image-center bg-image-cover"
            style={{ backgroundImage: "url(/images/video-bg.jpg)" }}
          >
            <div className="card-body d-flex justify-content-start p-2 position-absolute top-0 w-100 bg-gradiant-top">
              <figure className="avatar mb-0 mt-0 overflow-hidden">
                <img
                  src="/images/user-1.png"
                  alt="user-1"
                  className="z-index-1 shadow-sm rounded-circle w40"
                />
              </figure>
              <h5 className="text-white mt-1 fw-700 ml-2 z-index-1">
                Cabe Deo
                <span className="d-block font-xsssss mt-1 fw-400 text-grey-300 z-index-1">
                  2 hour
                </span>
              </h5>
              <span className="live-tag position-absolute right-15 mt-2 bg-danger p-2 z-index-1 rounded-lg text-white font-xsssss text-uppersace fw-700 ls-3">
                LIVE
              </span>
            </div>
            <div className="card-body text-center p-2 position-absolute w-100 bottom-0 bg-gradiant-bottom">
              <Link className="btn-round-xl d-md-inline-block d-none bg-blur m-3 mr-0 z-index-1">
                <i className="feather-grid text-white font-md" />
              </Link>
              <Link className="btn-round-xl d-md-inline-block d-none bg-blur m-3 z-index-1">
                <i className="feather-mic-off text-white font-md" />
              </Link>
              <Link className="btn-round-xxl bg-danger z-index-1">
                <i className="feather-phone-off text-white font-md" />
              </Link>
              <Link className="btn-round-xl d-md-inline-block d-none bg-blur m-3 z-index-1">
                <i className="ti-video-camera text-white font-md" />
              </Link>
              <Link className="btn-round-xl d-md-inline-block d-none bg-blur m-3 ml-0 z-index-1">
                <i className="ti-settings text-white font-md" />
              </Link>
              <span className="p-2 bg-blur z-index-1 text-white fw-700 font-xssss rounded-lg right-15 position-absolute mb-4 bottom-0">
                44:00
              </span>
            </div>
          </div>
          <div className="card d-block border-0 rounded-lg overflow-hidden dark-bg-transparent bg-transparent mt-4 pb-4">
            <div className="row">
              <div className="col-10">
                <h2 className="fw-700 font-md d-block lh-4 mb-2">
                  Microsoft Access Development, Design and Advanced Methods
                  Workshop Advance Tutorial
                </h2>
              </div>
              <div className="col-2">
                <Link className="btn-round-md ml-3 mb-2 d-inline-block float-right rounded-lg bg-danger">
                  <i className="feather-bookmark font-sm text-white" />
                </Link>
                <Link
                  className="btn-round-md ml-0 d-inline-block float-right rounded-lg bg-greylight"
                  id="dropdownMenu2"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="feather-share-2 font-sm text-grey-700" />
                </Link>
                <div
                  className="dropdown-menu dropdown-menu-right p-3 border-0 shadow-xss"
                  aria-labelledby="dropdownMenu2"
                >
                  <ul className="d-flex align-items-center mt-0 float-left">
                    <li className="mr-2">
                      <h4 className="fw-600 font-xss text-grey-900 mt-2 mr-3">
                        Share:
                      </h4>
                    </li>
                    <li className="mr-2">
                      <Link className="btn-round-md bg-facebook">
                        <i className="font-xs ti-facebook text-white" />
                      </Link>
                    </li>
                    <li className="mr-2">
                      <Link className="btn-round-md bg-twiiter">
                        <i className="font-xs ti-twitter-alt text-white" />
                      </Link>
                    </li>
                    <li className="mr-2">
                      <Link className="btn-round-md bg-linkedin">
                        <i className="font-xs ti-linkedin text-white" />
                      </Link>
                    </li>
                    <li className="mr-2">
                      <Link className="btn-round-md bg-instagram">
                        <i className="font-xs ti-instagram text-white" />
                      </Link>
                    </li>
                    <li className="mr-2">
                      <Link className="btn-round-md bg-pinterest">
                        <i className="font-xs ti-pinterest text-white" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <span className="font-xssss fw-700 text-grey-900 d-inline-block ml-0 text-dark">
              <b>Cassica Vanni</b>
            </span>
            <span className="dot ml-2 mr-2 d-inline-block btn-round-xss bg-grey" />
            <span className="font-xssss fw-600 text-grey-500 d-inline-block ml-1">
              Developer
            </span>
            <span className="font-xssss fw-600 text-grey-500 d-inline-block ml-1">
              Design
            </span>
            <span className="font-xssss fw-600 text-grey-500 d-inline-block ml-1">
              Developer
            </span>
            <span className="font-xssss fw-600 text-grey-500 d-inline-block ml-1">
              HTML5
            </span>
            <span className="font-xssss fw-600 text-grey-500 d-inline-block ml-1">
              Jquery
            </span>
            <span className="dot ml-2 mr-2 d-inline-block btn-round-xss bg-grey" />
          </div>
          <div className="card d-block border-0 bg-transparent dark-bg-transparent">
            <ul className="memberlist mt-0 mb-2 ml-0">
              <li className="w20">
                <Link>
                  <img
                    src="/images/user-6.png"
                    alt="user-6"
                    className="w40 d-inline-block"
                  />
                </Link>
              </li>
              <li className="w20">
                <Link>
                  <img
                    src="/images/user-7.png"
                    alt="user-7"
                    className="w40 d-inline-block"
                  />
                </Link>
              </li>
              <li className="w20">
                <Link>
                  <img
                    src="/images/user-8.png"
                    alt="user-8"
                    className="w40 d-inline-block"
                  />
                </Link>
              </li>
              <li className="w20">
                <Link>
                  <img
                    src="/images/user-3.png"
                    alt="user-3"
                    className="w40 d-inline-block"
                  />
                </Link>
              </li>
              <li className="w20">
                <Link>
                  <img
                    src="/images/user-5.png"
                    alt="user-5"
                    className="w40 d-inline-block"
                  />
                </Link>
              </li>
              <li className="w20">
                <Link>
                  <img
                    src="/images/user-4.png"
                    alt="user-4"
                    className="w40 d-inline-block"
                  />
                </Link>
              </li>
              <li className="pl-4 w-auto">
                <Link className="fw-500 text-grey-500 font-xssss">
                  Member already downloaded
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-xl-4 col-xxl-3">
          <div className="card w-100 d-block chat-body p-0 border-0 shadow-xss rounded-lg mb-3 position-relative">
            <div className="messages-content scroll-bar p-3">
              <div className="message-item">
                <div className="message-user">
                  <figure className="avatar">
                    <img src="/images/user-9.png" alt="user-9" />
                  </figure>
                  <div>
                    <h5 className="font-xssss mt-2">Byrom Guittet</h5>
                    <div className="time">01:35 PM</div>
                  </div>
                </div>
                <div className="message-wrap shadow-none">
                  I'm fine, how are you 😃
                </div>
              </div>
              <div className="message-item">
                <div className="message-user">
                  <figure className="avatar">
                    <img src="/images/user-1.png" alt="user-1" />
                  </figure>
                  <div>
                    <h5 className="font-xssss mt-2">Byrom Guittet</h5>
                    <div className="time">
                      01:35 PM
                      <i className="ti-double-check text-info" />
                    </div>
                  </div>
                </div>
                <div className="message-wrap shadow-none">
                  I want those files for you. I want you to send 1 PDF and 1
                  image file.
                </div>
              </div>
              <div className="message-item">
                <div className="message-user">
                  <figure className="avatar">
                    <img src="/images/user-9.png" alt="user-9" />
                  </figure>
                  <div>
                    <h5 className="font-xssss mt-2">Byrom Guittet</h5>
                    <div className="time">01:35 PM</div>
                  </div>
                </div>
                <div className="message-wrap shadow-none">
                  I've found some cool photos for our travel app.
                </div>
              </div>
              <div className="message-item outgoing-message">
                <div className="message-user">
                  <figure className="avatar">
                    <img src="/images/user-1.png" alt="user-1" />
                  </figure>
                  <div>
                    <h5>You</h5>
                    <div className="time">
                      01:35 PM
                      <i className="ti-double-check text-info" />
                    </div>
                  </div>
                </div>
                <div className="message-wrap">
                  Hey mate! How are things going ?
                </div>
              </div>
              <div className="message-item">
                <div className="message-user">
                  <figure className="avatar">
                    <img src="/images/user-9.png" alt="user-9" />
                  </figure>
                  <div>
                    <h5 className="font-xssss mt-2">Byrom Guittet</h5>
                    <div className="time">01:35 PM</div>
                  </div>
                </div>
                <div className="message-wrap shadow-none">
                  I'm fine, how are you 😃
                </div>
              </div>
              <div className="message-item">
                <div className="message-user">
                  <figure className="avatar">
                    <img src="/images/user-1.png" alt="user-1" />
                  </figure>
                  <div>
                    <h5 className="font-xssss mt-2">Byrom Guittet</h5>
                    <div className="time">
                      01:35 PM
                      <i className="ti-double-check text-info" />
                    </div>
                  </div>
                </div>
                <div className="message-wrap shadow-none">
                  I want those files for you. I want you to send 1 PDF and 1
                  image file.
                </div>
              </div>
              <div className="message-item">
                <div className="message-user">
                  <figure className="avatar">
                    <img src="/images/user-9.png" alt="user-9" />
                  </figure>
                  <div>
                    <h5 className="font-xssss mt-2">Byrom Guittet</h5>
                    <div className="time">01:35 PM</div>
                  </div>
                </div>
                <div className="message-wrap shadow-none">
                  I've found some cool photos for our travel app.
                </div>
              </div>
            </div>
            <form className="chat-form position-absolute bottom-0 w-100 left-0 bg-white z-index-1 p-3 shadow-xs theme-dark-bg">
              <button className="bg-grey float-left">
                <i className="ti-microphone text-white" />
              </button>
              <div className="form-group">
                <input type="text" placeholder="Start typing.." />
              </div>
              <button className="bg-current">
                <i className="ti-arrow-right text-white" />
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
