import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function ProgramImplementation() {
  const navigate = useNavigate();
  let isError = false;
  const [mode, setMode] = useState("add");

  const [activeId, setActiveId] = useState("");
  const [programs, setPrograms] = useState([]);
  const [courseId, setCourseId] = useState("");
  const [courseName, setCourseName] = useState("");
  const [implementation, setImplementation] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    let fieldName = [];
    if (!courseId.length) {
      fieldName.push("First Name");
    }
    if (!courseName.length) {
      fieldName.push("Course Name");
    }
    if (!implementation.length) {
      fieldName.push("Implementation");
    }

    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }

    if (isError) {
      return;
    }
    mode === "add" ? addProgram() : updateProgram();
  };

  const clearFields = () => {
    setCourseId("");
    setCourseName("");
    setImplementation("");
  };

  const addProgram = () => {
    const newProgram = {
      courseId: courseId,
      courseName: courseName,
      implementation: implementation,
    };

    setPrograms((prevPrograms) => [...prevPrograms, newProgram]);

    clearFields();
  };

  const editProgram = (courseId) => {
    setMode("edit");
    setActiveId(courseId);
    const program = programs.find((program) => program.courseId === courseId);
    setCourseId(program.courseId);
    setCourseName(program.courseName);
    setImplementation(program.implementation);
  };

  const deleteProgram = (id) => {
    const newPrograms = programs.filter((program) => program.courseId !== id);
    setPrograms(newPrograms);
  };
  const updateProgram = () => {
    const program = programs.find((program) => program.courseId === activeId);
    program.courseId = courseId;
    program.courseName = courseName;
    program.implementation = implementation;

    clearFields();
    setMode("add");
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
          Program Implementation
        </h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Course ID</label>
                <input
                  type="text"
                  name="course-id"
                  className="form-control"
                  onChange={(e) => setCourseId(e.target.value)}
                  value={courseId}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Course Name
                </label>
                <input
                  type="text"
                  name="course-name"
                  className="form-control"
                  onChange={(e) => setCourseName(e.target.value)}
                  value={courseName}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Implementation
                </label>
                <textarea
                  className="w-100 h100 style2-textarea p-3 form-control"
                  onChange={(e) => setImplementation(e.target.value)}
                  value={implementation}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
              <Link
                className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block"
                onClick={(e) => handleSubmit(e)}
              >
                {mode === "add" ? "Add" : "Edit"} Implementation
              </Link>
            </div>
          </div>
        </form>
      </div>
      {programs.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th className="bg-current text-white">Course ID</th>
                <th className="bg-current text-white">Course Name</th>
                <th className="bg-current text-white">Implementation</th>
                <th className="bg-current text-white">Actions</th>
              </tr>
            </thead>
            <tbody>
              {programs.map((program) => (
                <tr key={program.courseId}>
                  <td>{program.courseId}</td>
                  <td>{program.courseName}</td>
                  <td>{program.implementation}</td>
                  <td className="text-center">
                    <i
                      className="feather-edit text-current mr-sm-2 mr-md-3 mr-lg-4"
                      style={{ cursor: "pointer" }}
                      onClick={() => editProgram(program.courseId)}
                    />
                    <i
                      className="feather-trash text-red"
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteProgram(program.courseId)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default ProgramImplementation;
