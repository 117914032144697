import { Link } from "react-router-dom";

export default function Chat() {
  return (
    <div className="middle-sidebar-left bg-white dark-bg-transparent mr-3 pr-0">
      <div className="row">
        <div className="col-lg-6 col-xl-4 col-md-6 chat-left scroll-bar border-right-light pl-4 pr-4">
          <form action="#" className="mt-0 pl-3 pt-3">
            <div className="search-form">
              <i className="ti-search font-xs" />
              <input
                type="text"
                className="form-control text-grey-500 mb-0 bg-greylight border-0"
                placeholder="Search here."
              />
            </div>
          </form>
          <div className="section full mt-2 mb-2 pl-3">
            <ul className="list-group list-group-flush">
              <li className="bg-transparent list-group-item no-icon pl-0">
                <figure className="avatar float-left mb-0 mr-3">
                  <img
                    src="/images/user-8.png"
                    alt="user-8.png"
                    className="w45"
                  />
                </figure>
                <h3 className="fw-700 mb-0 mt-1">
                  <Link className="font-xsss text-grey-900 text-dark d-block">
                    Hurin Seary
                  </Link>
                </h3>
                <span className="d-block">What's up, how are you?</span>
                <span className="badge mt-0 text-grey-500 badge-pill">
                  4:09 pm
                </span>
                <div className="snippet float-right" data-title=".dot-typing">
                  <div className="stage">
                    <div className="dot-typing" />
                  </div>
                </div>
              </li>
              <li className="bg-transparent list-group-item no-icon pl-0">
                <figure className="avatar float-left mb-0 mr-3">
                  <img
                    src="/images/user-7.png"
                    alt="user-7.png"
                    className="w45"
                  />
                </figure>
                <h3 className="fw-700 mb-0 mt-1">
                  <Link className="font-xsss text-grey-900 text-dark d-block">
                    Victor Exrixon
                  </Link>
                </h3>
                <span className="d-block">What's up, how are you?</span>
                <span className="badge badge-primary text-white badge-pill">
                  2
                </span>
              </li>
              <li className="bg-transparent list-group-item no-icon pl-0">
                <figure className="avatar float-left mb-0 mr-3">
                  <img
                    src="/images/user-12.png"
                    alt="user-12.png"
                    className="w45"
                  />
                </figure>
                <h3 className="fw-700 mb-0 mt-1">
                  <Link className="font-xsss text-grey-900 text-dark d-block">
                    Surfiya Zakir
                  </Link>
                </h3>
                <span className="d-block">What's up, how are you?</span>
                <span className="badge badge-danger text-white badge-pill">
                  6
                </span>
              </li>
              <li className="bg-transparent list-group-item no-icon pl-0">
                <figure className="avatar float-left mb-0 mr-3">
                  <img
                    src="/images/user-4.png"
                    alt="user-4.png"
                    className="w45"
                  />
                </figure>
                <h3 className="fw-700 mb-0 mt-1">
                  <Link className="font-xsss text-grey-900 text-dark d-block">
                    Goria Coast
                  </Link>
                </h3>
                <span className="d-block">What's up, how are you?</span>
                <span className="badge badge-primary text-white badge-pill">
                  8
                </span>
              </li>
              <li className="bg-transparent list-group-item pl-0">
                <figure className="avatar float-left mb-0 mr-3">
                  <img
                    src="/images/user-3.png"
                    alt="user-3.png"
                    className="w45"
                  />
                </figure>
                <h3 className="fw-700 mb-0 mt-1">
                  <Link className="font-xsss text-grey-900 text-dark d-block">
                    Hurin Seary
                  </Link>
                </h3>
                <span className="d-block">What's up, how are you?</span>
              </li>
              <li className="bg-transparent list-group-item pl-0">
                <figure className="avatar float-left mb-0 mr-3">
                  <img
                    src="/images/user-2.png"
                    alt="user-2.png"
                    className="w45"
                  />
                </figure>
                <h3 className="fw-700 mb-0 mt-1">
                  <Link className="font-xsss text-grey-900 text-dark d-block">
                    Victor Exrixon
                  </Link>
                </h3>
                <span className="d-block">What's up, how are you?</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-6 col-xl-8 col-md-6 pl-0 d-none d-lg-block d-md-block">
          <div className="chat-wrapper pt-0 w-100 position-relative scroll-bar">
            <div className="chat-body p-3">
              <div className="messages-content pb-5">
                <div className="message-item">
                  <div className="message-user">
                    <figure className="avatar">
                      <img src="/images/user-9.png" alt="user-9.png" />
                    </figure>
                    <div>
                      <h5>Werner Daniel</h5>
                      <div className="time">01:35 PM</div>
                    </div>
                  </div>
                  <div className="message-wrap">I'm fine, how are you 😃</div>
                </div>
                <div className="message-item outgoing-message">
                  <div className="message-user">
                    <figure className="avatar">
                      <img src="/images/user-1.png" alt="user-1.png" />
                    </figure>
                    <div>
                      <h5>Brielle Cormier</h5>
                      <div className="time">
                        01:35 PM
                        <i className="ti-double-check text-info" />
                      </div>
                    </div>
                  </div>
                  <div className="message-wrap">
                    I want those files for you. I want you to send 1 PDF and 1
                    image file.
                  </div>
                </div>
                <div className="message-item">
                  <div className="message-user">
                    <figure className="avatar">
                      <img src="/images/user-9.png" alt="user-9.png" />
                    </figure>
                    <div>
                      <h5>Paolo Weber</h5>
                      <div className="time">01:35 PM</div>
                    </div>
                  </div>
                  <div className="message-wrap">
                    I've found some cool photos for our travel app.
                  </div>
                </div>
                <div className="message-item outgoing-message">
                  <div className="message-user">
                    <figure className="avatar">
                      <img src="/images/user-1.png" alt="user-1.png" />
                    </figure>
                    <div>
                      <h5>Lisette Collins</h5>
                      <div className="time">
                        01:35 PM
                        <i className="ti-double-check text-info" />
                      </div>
                    </div>
                  </div>
                  <div className="message-wrap">
                    Hey mate! How are things going ?
                  </div>
                </div>
                <div className="message-item">
                  <div className="message-user">
                    <figure className="avatar">
                      <img src="/images/user-9.png" alt="user-9.png" />
                    </figure>
                    <div>
                      <h5>Candido Pouros</h5>
                      <div className="time">01:35 PM</div>
                    </div>
                  </div>
                  <figure>
                    <img
                      src="/images/bb-9.jpg"
                      className="w-25 img-fluid rounded-lg"
                      alt="bb-9.jpg"
                    />
                  </figure>
                </div>
                <div className="message-item outgoing-message">
                  <div className="message-user">
                    <figure className="avatar">
                      <img src="/images/user-1.png" alt="user-1.png" />
                    </figure>
                    <div>
                      <h5>Angela Hermiston</h5>
                      <div className="time">
                        01:35 PM
                        <i className="ti-double-check text-info" />
                      </div>
                    </div>
                  </div>
                  <div className="message-wrap" style={{ marginBottom: 90 }}>
                    Hey mate! How are things going ?
                  </div>
                </div>
                <div className="clearfix" />
              </div>
            </div>
          </div>
          <div
            className="chat-bottom dark-bg p-3 shadow-xss"
            style={{ width: "98%" }}
          >
            <form className="chat-form">
              <button className="bg-grey float-left">
                <i className="ti-microphone text-grey-600" />
              </button>
              <div className="form-group">
                <input type="text" placeholder="Start typing.." />
              </div>
              <button className="bg-current">
                <i className="ti-arrow-right text-white" />
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
