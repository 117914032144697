import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function SupportAndEnquiries() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    courseName: "",
    username: "",
    query: "",
    resolution: "",
  });

  const [tableData, setTableData] = useState([
    {
      courseName: "Operating System",
      username: "Marc Blanda",
      query: "How does Fork() work in Linux?",
      resolution: "Fork() is a system call that creates a new process.",
    },
    {
      courseName: "Data Structures",
      username: "Alice Johnson",
      query: "Explain the concept of linked lists.",
      resolution: "A linked list is a data structure...",
    },
  ]);

  const [editIndex, setEditIndex] = useState(null);

  const handleEdit = (index) => {
    const rowData = tableData[index];
    setFormData({ ...rowData });
    setEditIndex(index);
  };

  const handleResolve = () => {
    if (editIndex !== null) {
      const updatedTableData = [...tableData];
      updatedTableData[editIndex] = { ...formData };
      setTableData(updatedTableData);
      setFormData({
        courseName: "",
        username: "",
        query: "",
        resolution: "",
      });
      setEditIndex(null);
    }
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
          Support & Enquiries
        </h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form action="#">
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Course Name
                </label>
                <input
                  type="text"
                  name="courseName"
                  className="form-control"
                  value={formData.courseName}
                  onChange={(e) =>
                    setFormData({ ...formData, courseName: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Username</label>
                <input
                  type="text"
                  name="username"
                  className="form-control"
                  value={formData.username}
                  onChange={(e) =>
                    setFormData({ ...formData, username: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Query</label>
                <textarea
                  className="w-100 h100 style2-textarea p-3 form-control"
                  name="query"
                  value={formData.query}
                  onChange={(e) =>
                    setFormData({ ...formData, query: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Resolution</label>
                <textarea
                  className="w-100 h100 style2-textarea p-3 form-control"
                  name="resolution"
                  value={formData.resolution}
                  onChange={(e) =>
                    setFormData({ ...formData, resolution: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
              <Link
                className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block"
                onClick={handleResolve}
              >
                Resolve Query
              </Link>
            </div>
          </div>
        </form>
      </div>
      <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
        <table className="table table-bordered data-table">
          <thead>
            <tr>
              <th className="bg-current text-white">Course Name</th>
              <th className="bg-current text-white">Username</th>
              <th className="bg-current text-white">Query</th>
              <th className="bg-current text-white">Resolution</th>
              <th className="bg-current text-white">Actions</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((data, index) => (
              <tr key={index}>
                <td>{data.courseName}</td>
                <td>{data.username}</td>
                <td>{data.query}</td>
                <td>{data.resolution}</td>
                <td className="text-center">
                  <i
                    className="feather-edit text-current mr-sm-2 mr-md-3 mr-lg-4"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleEdit(index)}
                  />
                  {/*<i className="feather-trash text-red"
                  style={{ cursor: "pointer" }} />*/}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SupportAndEnquiries;
/*
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function SupportAndEnquiries() {
  const navigate = useNavigate();
  let isError = false;
  const [mode, setMode] = useState("resolve");

  const [activeId, setActiveId] = useState("");
  const [queryies, setQueries] = useState([]);
  const [username, setUsername] = useState("");
  const [courseName, setCourseName] = useState("");
  const [queryy, setQueryy] = useState("");
  const [resolution, setResoluion] = useState("");

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
          Support & Enquiries
        </h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form action="#">
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Course Name
                </label>
                <input
                  type="text"
                  name="comment-name"
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Username</label>
                <input
                  type="text"
                  name="comment-name"
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Query</label>
                <textarea className="w-100 h100 style2-textarea p-3 form-control" />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Resolution</label>
                <textarea className="w-100 h100 style2-textarea p-3 form-control" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
              <Link className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block">
                Resolve Query
              </Link>
            </div>
          </div>
        </form>
      </div>
      <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
        <table className="table table-bordered data-table">
          <thead>
            <tr>
              <th className="bg-current text-white">Course Name</th>
              <th className="bg-current text-white">Username</th>
              <th className="bg-current text-white">Query</th>
              <th className="bg-current text-white">Resolution</th>
              <th className="bg-current text-white">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Operating System</td>
              <td>Marc Blanda</td>
              <td>How does Fork() work in Linux?</td>
              <td>Fork() is a system call that creates a new process.</td>
              <td className="text-center">
                <i className="feather-edit text-current mr-sm-2 mr-md-3 mr-lg-4" />
                <i className="feather-trash text-red" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SupportAndEnquiries;

*/
