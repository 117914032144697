import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function CreateExam(props) {
  const navigate = useNavigate();
  const [mode, setMode] = useState("create");
  const [id, setId] = useState(1);
  const [activeId, setActiveId] = useState(-1);
  const [exams, setExams] = useState([]);
  const [examName, setExamName] = useState("");
  const [examDuration, setExamDuration] = useState("");

  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState("");
  const [newOptions, setNewOptions] = useState(["", "", "", ""]);
  const [newCorrectAnswer, setNewCorrectAnswer] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const requiredFields = [];

    if (!examName.length) {
      requiredFields.push("Exam Name");
    }
    if (!examDuration.length) {
      requiredFields.push("Exam Duration");
    }
    if (questions.some((question) => question.question.trim() === "")) {
      requiredFields.push("Question");
    }
    if (questions.some((question) => !question.options.length)) {
      requiredFields.push("Options");
    }
    if (questions.some((question) => !question.correctAnswer.trim())) {
      requiredFields.push("Correct Answer");
    }

    if (requiredFields.length > 0) {
      for (const field of requiredFields) {
        toast.error(`${field} is Required!`);
      }
      return;
    }

    if (mode === "create") {
      createExam();
    } else if (mode === "edit") {
      updateExam();
    }
  };

  const createExam = () => {
    const newExam = {
      id: id,
      examName: examName,
      examDuration: examDuration,
      questions: questions,
    };

    setExams((prevExams) => [...prevExams, newExam]);

    clearFields();
    setId(id + 1);
  };

  const addQuestion = () => {
    if (newQuestion) {
      setQuestions((prevQuestions) => [
        ...prevQuestions,
        {
          question: newQuestion,
          options: newOptions,
          correctAnswer: newCorrectAnswer,
        },
      ]);
      setNewQuestion("");
      setNewOptions(["", "", "", ""]);
      setNewCorrectAnswer("");
    }
  };

  const clearFields = () => {
    setExamName("");
    setExamDuration("");
    setNewQuestion("");
    setNewOptions(["", "", "", ""]);
    setNewCorrectAnswer("");
    setQuestions([]);
  };

  const editExam = (id) => {
    setMode("edit");
    setActiveId(id);
    const exam = exams.find((exam) => exam.id === id);
    setExamName(exam.examName);
    setExamDuration(exam.examDuration);
    setQuestions(exam.questions);
  };

  const deleteExam = (id) => {
    const newExamList = exams.filter((exam) => exam.id !== id);
    setExams(newExamList);
  };

  const updateExam = () => {
    const updatedExam = exams.map((exam) => {
      if (exam.id === activeId) {
        return {
          ...exam,
          examName,
          examDuration,
        };
      }
      return exam;
    });

    setExams(updatedExam);
    setMode("create");
    setActiveId(-1);
    clearFields();
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
          Assessment Creation
        </h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Exam Name</label>
                <input
                  type="text"
                  name="exam-name"
                  className="form-control"
                  onChange={(e) => setExamName(e.target.value)}
                  value={examName}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Exam Duration
                </label>
                <input
                  type="text"
                  name="exam-duration"
                  className="form-control"
                  onChange={(e) => setExamDuration(e.target.value)}
                  value={examDuration}
                />
              </div>
            </div>
          </div>
        </form>
        {questions.map((question, index) => (
          <div key={index} className="mb-4">
            <div className="form-group">
              <label className="mont-font fw-600 font-xsss">
                Question {index + 1}
              </label>
              <input
                type="text"
                name={`new-question-${index}`}
                className="form-control"
                value={question.question}
                disabled
              />
            </div>
            <label className="mont-font fw-600 font-xsss">Options</label>
            {question.options.map((option, optionIndex) => (
              <div key={optionIndex} className="form-group">
                <input
                  type="text"
                  name={`option-${index}-${optionIndex}`}
                  className="form-control"
                  value={option}
                  disabled
                />
              </div>
            ))}
            <label className="mont-font fw-600 font-xsss">Correct Answer</label>
            <input
              type="text"
              name={`correct-answer-${index}`}
              className="form-control"
              value={question.correctAnswer}
              disabled
            />
          </div>
        ))}
        <div className="mb-4">
          <div className="form-group">
            <label className="mont-font fw-600 font-xsss">
              Question {questions.length + 1}
            </label>
            <input
              type="text"
              name="new-question"
              className="form-control"
              onChange={(e) => setNewQuestion(e.target.value)}
              value={newQuestion}
            />
          </div>
          <label className="mont-font fw-600 font-xsss">Options</label>
          {newOptions.map((option, index) => (
            <div key={index} className="form-group">
              <input
                type="text"
                name={`option-${index}`}
                className="form-control"
                value={option}
                onChange={(e) => {
                  const updatedOptions = [...newOptions];
                  updatedOptions[index] = e.target.value;
                  setNewOptions(updatedOptions);
                }}
              />
            </div>
          ))}
          <label className="mont-font fw-600 font-xsss">Correct Answer</label>
          <input
            type="text"
            name="correct-answer"
            className="form-control"
            onChange={(e) => setNewCorrectAnswer(e.target.value)}
            value={newCorrectAnswer}
          />
          <button onClick={addQuestion} className="btn btn-primary mt-4">
            Add Question
          </button>
        </div>
        <div className="col-lg-12 d-flex align-items-end justify-content-end">
          <Link
            className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block"
            type="submit"
            onClick={(e) => handleSubmit(e)}
          >
            {mode === "create" ? "Create" : "Edit"} Exam
          </Link>
        </div>
      </div>
      {exams.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th className="bg-current text-white">Exam ID</th>
                <th className="bg-current text-white">Exam Name</th>
                <th className="bg-current text-white">Exam Duration</th>
                <th className="bg-current text-white">Questions</th>
                <th className="bg-current text-white">Actions</th>
              </tr>
            </thead>
            <tbody>
              {exams.map((exam) => (
                <tr key={exam.id}>
                  <td>{exam.id}</td>
                  <td>{exam.examName}</td>
                  <td>{exam.examDuration}</td>
                  <td>
                    <ul>
                      {exam.questions.map((question, index) => (
                        <li key={index}>
                          <strong>Question {index + 1}:</strong>{" "}
                          {question.question}
                          <br />
                          <strong>Options:</strong>{" "}
                          {question.options.join(", ")}
                          <br />
                          <strong>Correct Answer:</strong>{" "}
                          {question.correctAnswer}
                          <br />
                          <br />
                        </li>
                      ))}
                    </ul>
                  </td>
                  <td className="text-center">
                    <i
                      className="feather-edit text-current mr-sm-2 mr-md-3 mr-lg-4"
                      style={{ cursor: "pointer" }}
                      onClick={() => editExam(exam.id)}
                    />
                    <i
                      className="feather-trash text-red"
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteExam(exam.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default CreateExam;
