import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Accreditation() {
  const navigate = useNavigate();
  let isError = false;

  const [mode, setMode] = useState("add");
  const [id, setId] = useState(1);
  const [activeId, setActiveId] = useState(-1);
  const [accreditations, setAccreditations] = useState([]);
  const [accreditationName, setAccreditationName] = useState("");
  const [accreditationRank, setAccreditationRank] = useState("");
  const [statusOptions] = useState([
    { value: "Todo", label: "Todo" },
    { value: "In Progress", label: "In Progress" },
    { value: "Done", label: "Done" },
  ]);
  const [selectedStatus, setSelectedStatus] = useState("");

  const handleStatusSelection = (e) => {
    setSelectedStatus(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let fieldName = [];
    if (!accreditationName) {
      fieldName.push("Accreditation  Name");
    }
    if (!accreditationRank) {
      fieldName.push("Accreditation Rank");
    }
    if (!selectedStatus) {
      fieldName.push("Selected Status");
    }
    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }
    if (isError) {
      return;
    }
    if (mode === "add") {
      addAccreditation();
    } else {
      updateAccreditation();
    }
  };

  const addAccreditation = () => {
    const newAccreditation = {
      id: id,
      accreditationName: accreditationName,
      accreditationRank: accreditationRank,
      selectedStatus: selectedStatus,
    };
    setAccreditations((prevAccreditations) => [
      ...prevAccreditations,
      newAccreditation,
    ]);
    clearFields();
    setId(id + 1);
  };

  const clearFields = () => {
    setAccreditationName("");
    setAccreditationRank("");
    setSelectedStatus("");
  };

  const editAccreditation = (id) => {
    setMode("edit");
    setActiveId(id);
    const accreditation = accreditations.find(
      (accreditation) => accreditation.id === id
    );
    setAccreditationName(accreditation.accreditationName);
    setAccreditationRank(accreditation.accreditationRank);
    setSelectedStatus(accreditation.selectedStatus);
  };

  const deleteAccreditation = (id) => {
    const newAccreditations = accreditations.filter(
      (accreditation) => accreditation.id !== id
    );
    setAccreditations(newAccreditations);
  };

  const updateAccreditation = () => {
    const accreditation = accreditations.find(
      (accreditation) => accreditation.id === activeId
    );
    accreditation.accreditationName = accreditationName;
    accreditation.accreditationRank = accreditationRank;
    accreditation.selectedStatus = selectedStatus;
    clearFields();
    setMode("add");
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
          Accreditation
        </h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form action="#">
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Accreditation Name
                </label>
                <input
                  type="text"
                  name="accreditation-name"
                  className="form-control"
                  value={accreditationName}
                  onChange={(e) => setAccreditationName(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Accreditation Rank
                </label>
                <input
                  type="text"
                  name="accreditaion-Rank"
                  className="form-control"
                  value={accreditationRank}
                  onChange={(e) => setAccreditationRank(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <select
                  className="form-control"
                  name="status"
                  id="status"
                  value={selectedStatus}
                  onChange={handleStatusSelection}
                >
                  <option value="">Status</option>
                  {statusOptions.map((status) => (
                    <option key={status.value} value={status.value}>
                      {status.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-6 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
              <Link
                className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block"
                onClick={handleSubmit}
              >
                {mode === "add" ? "Add" : "Edit"} Accreditation
              </Link>
            </div>
          </div>
        </form>
      </div>
      {accreditations.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th className="bg-current text-white">Accreditation ID</th>
                <th className="bg-current text-white">Accreditation Name</th>
                <th className="bg-current text-white">Accreditation Rank</th>
                <th className="bg-current text-white">Status</th>
                <th className="bg-current text-white">Actions</th>
              </tr>
            </thead>
            <tbody>
              {accreditations.map((accreditaion) => (
                <tr key={accreditaion.id}>
                  <td>{accreditaion.id}</td>
                  <td>{accreditaion.accreditationName}</td>
                  <td>{accreditaion.accreditationRank}</td>
                  <td>{accreditaion.selectedStatus}</td>
                  <td className="text-center">
                    <i
                      className="feather-edit text-current mr-sm-2 mr-md-3 mr-lg-4"
                      style={{ cursor: "pointer" }}
                      onClick={() => editAccreditation(accreditaion.id)}
                    />
                    <i
                      className="feather-trash text-red"
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteAccreditation(accreditaion.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Accreditation;
