import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./layout/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import Courses from "./pages/Courses";
import Contact from "./pages/Contact";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import VerifyEmail from "./pages/auth/VerifyEmail";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import AuthLayout from "./layout/Auth/AuthLayout";
import DashLayout from "./layout/Dashboard/DashLayout";
import Admin from "./pages/dashboard/admin/Admin";
import Instructor from "./pages/dashboard/instructor/Instructor";
import Student from "./pages/dashboard/student/Student";
import Coordinator from "./pages/dashboard/coordinator/Coordinator";
import QA from "./pages/dashboard/qa/QA";
import Analytics from "./pages/dashboard/admin/Analytics";
import Messages from "./pages/dashboard/Messages";
import AccountInformation from "./pages/dashboard/settings/AccountInformation";
import ContactInformation from "./pages/dashboard/settings/ContactInformation";
import SettingsLayout from "./layout/Dashboard/SettingsLayout";
import Settings from "./pages/dashboard/settings/Settings";
import Social from "./pages/dashboard/settings/Social";
import Payment from "./pages/dashboard/settings/Payment";
import Password from "./pages/dashboard/settings/Password";
import Livestream from "./pages/dashboard/LiveStream";
import ManageCourses from "./pages/dashboard/admin/ManageCourses";
import Reports from "./pages/dashboard/admin/Reports";
import AssignStudents from "./pages/dashboard/admin/AssignStudents";
import Reviews from "./pages/dashboard/admin/Reviews";
import AssignSupportTickets from "./pages/dashboard/admin/AssignSupportTickets";
import ManageAdmin from "./pages/dashboard/admin/ManageAdmin";
import ManageUsers from "./pages/dashboard/admin/ManageUsers";
import Blog from "./pages/Blog";
import Exam from "./pages/Exam";
import ManageInstructor from "./pages/dashboard/instructor/ManageInstructor";
import GradeStudents from "./pages/dashboard/instructor/GradeStudents";
import CreateCourses from "./pages/dashboard/instructor/CreateCourses";
import CreateExam from "./pages/dashboard/instructor/CreateExam";
import ManageStudent from "./pages/dashboard/student/ManageStudent";
import CourseDetails from "./pages/dashboard/student/CourseDetails";
import ManageCoordinator from "./pages/dashboard/coordinator/ManageCoordinator";
import ProgramImplementation from "./pages/dashboard/coordinator/ProgramImplementation";
import SupportAndEnquiries from "./pages/dashboard/coordinator/SupportAndEnquiries";
import StudentPerformanceData from "./pages/dashboard/coordinator/StudentPerformanceData";
import ManageQA from "./pages/dashboard/qa/ManageQA";
import CurriculumEvaluation from "./pages/dashboard/qa/CurriculumEvaluation";
import PerformanceAssessment from "./pages/dashboard/qa/PerformanceAssessment";
import QSCompliance from "./pages/dashboard/qa/QSCompliance";
import FacultyDevelopment from "./pages/dashboard/qa/FacultyDevelopment";
import AssessmentTools from "./pages/dashboard/qa/AssessmentTools";
import ImprovementInitiatives from "./pages/dashboard/qa/ImprovementInitiatives";
import Accreditation from "./pages/dashboard/qa/Accreditation";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "../context/authProvider";
import Auth from "./pages/Auth";
import CourseContent from "./pages/dashboard/instructor/CourseContent";

export default function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="courses" element={<Courses />} />
            <Route path="contact" element={<Contact />} />
            <Route path="blog" element={<Blog />} />
            {/* <Route path="*" element={<NoPage />} /> */}
          </Route>
          <Route path="/auth" element={<AuthLayout />}>
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="verify-email" element={<VerifyEmail />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
          </Route>
          <Route
            element={
              <Auth
                allowedRoles={[
                  "admin",
                  "instructor",
                  "coordinator",
                  "qa",
                  "student",
                ]}
              />
            }
          >
            <Route path="/dashboard" element={<DashLayout />}>
              <Route element={<Auth allowedRoles={["admin"]} />}>
                <Route path="admin" element={<Admin />}>
                  <Route index element={<ManageAdmin />} />
                  <Route
                    path="manage-students"
                    element={<ManageUsers manage="Student" />}
                  />
                  <Route
                    path="manage-courses"
                    element={
                      <ManageCourses
                        title="Manage Courses"
                        role="Student"
                        dis="Courses"
                      />
                    }
                  />
                  <Route path="reports" element={<Reports />} />
                  <Route
                    path="manage-instructors"
                    element={<ManageUsers manage="Instructor" />}
                  />
                  <Route
                    path="handle-courses"
                    element={
                      <ManageCourses
                        title="Handle Courses"
                        role="Instructor"
                        dis="Courses"
                      />
                    }
                  />
                  <Route path="assign-students" element={<AssignStudents />} />
                  <Route path="reviews" element={<Reviews />} />
                  <Route
                    path="manage-coordinators"
                    element={<ManageUsers manage="Coordinator" />}
                  />
                  <Route
                    path="handle-programs"
                    element={
                      <ManageCourses
                        title="Handle Programs"
                        role="Coordinator"
                        dis="Programs"
                      />
                    }
                  />
                  <Route
                    path="assign-support-tickets"
                    element={<AssignSupportTickets />}
                  />
                  <Route path="analytics" element={<Analytics />} />
                  <Route
                    path="manage-qa"
                    element={<ManageUsers manage="QA Officer" />}
                  />
                  <Route
                    path="assign-course"
                    element={
                      <ManageCourses
                        title="Assign Course"
                        role="QA Officer"
                        dis="Courses"
                      />
                    }
                  />
                </Route>
              </Route>
              <Route element={<Auth allowedRoles={["instructor"]} />}>
                <Route path="instructor" element={<Instructor />}>
                  <Route index element={<ManageInstructor />} />
                  <Route path="create-courses" element={<CreateCourses />} />
                  <Route path="course-content" element={<CourseContent />} />
                  <Route path="create-exam" element={<CreateExam />} />
                  <Route path="grade-students" element={<GradeStudents />} />
                </Route>
              </Route>
              <Route element={<Auth allowedRoles={["student"]} />}>
                <Route path="student" element={<Student />}>
                  <Route index element={<ManageStudent />} />
                  <Route path="course-details" element={<CourseDetails />} />
                </Route>
              </Route>
              <Route element={<Auth allowedRoles={["coordinator"]} />}>
                <Route path="coordinator" element={<Coordinator />}>
                  <Route index element={<ManageCoordinator />} />
                  <Route
                    path="program-implementation"
                    element={<ProgramImplementation />}
                  />
                  <Route
                    path="support-and-enquiries"
                    element={<SupportAndEnquiries />}
                  />
                  <Route
                    path="student-performance-data"
                    element={<StudentPerformanceData />}
                  />
                </Route>
              </Route>
              <Route element={<Auth allowedRoles={["qa"]} />}>
                <Route path="qa" element={<QA />}>
                  <Route index element={<ManageQA />} />
                  <Route
                    path="curriculum-evaluation"
                    element={<CurriculumEvaluation />}
                  />
                  <Route
                    path="performance-assessment"
                    element={<PerformanceAssessment />}
                  />
                  <Route path="qs-compliance" element={<QSCompliance />} />
                  <Route
                    path="support-and-enquiries"
                    element={<SupportAndEnquiries />}
                  />
                  <Route
                    path="faculty-development"
                    element={<FacultyDevelopment />}
                  />
                  <Route
                    path="assessment-tools"
                    element={<AssessmentTools />}
                  />
                  <Route
                    path="improvement-initiatives"
                    element={<ImprovementInitiatives />}
                  />
                  <Route path="reporting" element={<Reports />} />
                  <Route path="accreditation" element={<Accreditation />} />
                  <Route path="reviews" element={<Reviews />} />
                </Route>
              </Route>
              <Route path="livestream" element={<Livestream />} />
              <Route path="exam" element={<Exam />} />
              <Route path="settings" element={<SettingsLayout />}>
                <Route index element={<Settings />} />
                <Route
                  path="account-information"
                  element={<AccountInformation />}
                />
                <Route path="saved-address" element={<ContactInformation />} />
                <Route path="social-account" element={<Social />} />
                <Route path="mycards" element={<Payment />} />
                <Route path="password" element={<Password />} />
              </Route>
              <Route path="analytics" element={<Analytics />} />
              <Route path="messages" element={<Messages />} />
              <Route path="exam" element={<Exam />} />
            </Route>
          </Route>
        </Routes>
        <ToastContainer />
      </AuthProvider>
    </BrowserRouter>
  );
}
