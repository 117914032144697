import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CreatableSelect from "react-select/creatable";

function CourseContent() {
  const navigate = useNavigate();
  let isError = false;
  const [mode, setMode] = useState("create");
  const [id, setId] = useState(1);
  const [activeId, setActiveId] = useState(-1);
  const [courses, setCourses] = useState([]);
  const [courseName, setCourseName] = useState("");
  const [sections, setSections] = useState([]);
  const [sectionTitle, setSectionTitle] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    let fieldName = [];
    if (!courseName.length) {
      fieldName.push("Course Name");
    }

    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }
    if (isError) {
      return;
    }
    mode === "create" ? createCourse() : updateCourse();
  };

  const clearFields = () => {
    setCourseName("");
    setMode("create");
  };

  const createCourse = () => {
    const newCourse = {
      id: id,
      courseName: courseName,
      sections: sections,
    };

    setCourses((prevCourses) => [...prevCourses, newCourse]);

    clearFields();
    setId(id + 1);
  };

  const addSection = () => {
    if (sectionTitle.trim()) {
      setSections((prevSections) => [
        ...prevSections,
        { title: sectionTitle, videoTitles: [] },
      ]);
      setSectionTitle("");
    }
  };

  const editCourseContent = (id) => {
    setMode("edit");
    setActiveId(id);
    const course = courses.find((course) => course.id === id);
    setCourseName(course.courseName);
    setSections(course.sections);
  };

  const deleteCourseContent = (id) => {
    const newCourse = courses.filter((course) => course.id !== id);
    setCourses(newCourse);
  };

  const updateCourse = () => {
    const course = courses.find((course) => course.id === activeId);
    course.courseName = courseName;
    course.sections = sections;

    clearFields();
  };

  const [selectedOptions] = useState([]);
  const [customOptions] = useState([]);

  const options = [...selectedOptions, ...customOptions];

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
          Course Content
        </h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Course Name
                </label>
                <input
                  type="text"
                  name="course-name"
                  className="form-control"
                  onChange={(e) => setCourseName(e.target.value)}
                  value={courseName}
                />
              </div>
            </div>
          </div>
        </form>
        <div className="section-form mb-4">
          <div className="form-group">
            <label className="mont-font fw-600 font-xsss">Section Title</label>
            <input
              type="text"
              name="section-title"
              className="form-control"
              onChange={(e) => setSectionTitle(e.target.value)}
              value={sectionTitle}
            />
          </div>
          <button onClick={addSection} className="btn btn-primary">
            Add Section
          </button>
        </div>
        {sections.map((section, sectionIndex) => (
          <div key={sectionIndex} className="video-title-form mb-4">
            <div className="form-group">
              <label className="mont-font fw-600 font-xss">
                Video Titles for Section: {section.title}
              </label>
              <CreatableSelect
                name={`video-title-${sectionIndex}`}
                isMulti
                onChange={(selectedOptions) => {
                  const updatedSections = [...sections];
                  updatedSections[sectionIndex].videoTitles =
                    selectedOptions.map((option) => option.value);
                  setSections(updatedSections);
                }}
                options={options}
              />
            </div>
          </div>
        ))}
        <div className="col-lg-12 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
          <Link
            className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block"
            type="submit"
            onClick={(e) => handleSubmit(e)}
          >
            {mode === "create" ? "Create" : "Edit"} Course Content
          </Link>
        </div>
      </div>
      {courses.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th className="bg-current text-white">Course ID</th>
                <th className="bg-current text-white">Course Name</th>
                <th className="bg-current text-white">Course Content</th>
                <th className="bg-current text-white">Actions</th>
              </tr>
            </thead>
            <tbody>
              {courses.map((course) => (
                <tr key={course.id}>
                  <td>{course.id}</td>
                  <td>{course.courseName}</td>
                  <td>
                    <ul>
                      {course.sections.map((section, sectionIndex) => (
                        <li key={sectionIndex}>
                          <strong>Section:</strong> {section.title}
                          <br />
                          <strong>Video Titles:</strong>{" "}
                          {section.videoTitles.join(", ")}
                        </li>
                      ))}
                    </ul>
                  </td>
                  <td className="text-center">
                    <i
                      className="feather-edit text-current mr-sm-2 mr-md-3 mr-lg-4"
                      style={{ cursor: "pointer" }}
                      onClick={() => editCourseContent(course.id)}
                    />
                    <i
                      className="feather-trash text-red"
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteCourseContent(course.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default CourseContent;
