import env from "../../../env";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

export default function VerifyEmail() {
  const baseUrl = env.BASE_URL;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const url = searchParams.get("url");
  const expires = searchParams.get("expires");
  const signature = searchParams.get("signature");

  let verified = useRef(false);
  useEffect(() => {
    const apiCall = async () => {
      const parseUrl = baseUrl + "/email/verify" + url;
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };

      const verifyUrl = `${parseUrl}?expires=${expires}&signature=${signature}`;

      try {
        const response = await axios.get(verifyUrl, {
          headers,
        });
        toast.success(response.data.message);
      } catch (error) {
        toast.error(error);
      }
    };
    if (!verified.current) {
      apiCall();
      verified.current = true;
    }
  });

  return (
    <div className="row">
      <div
        className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
        style={{ backgroundImage: "url(/images/login-bg.jpg)" }}
      />
      <div className="col-xl-7 vh-lg-100 align-items-center d-flex bg-white rounded-lg overflow-hidden">
        <div className="card shadow-none border-0 ml-auto mr-auto login-card">
          <div className="card-body rounded-0 text-left">
            <h2 className="fw-700 display1-size display2-md-size mb-3">
              Email Verified!
            </h2>
            <div className="col-sm-12 p-0 text-left">
              <p className="fw-600">
                Thank You! Your email has been verified. Your account is active
                now.
                <br />
                Please login to start using your account
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
