import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function QSCompliance() {
  const navigate = useNavigate();
  let isError = false;

  const [mode, setMode] = useState("add");

  const [activeId, setActiveId] = useState(-1);
  const [compliances, setCompliances] = useState([]);
  const [complianceId, setComplianceId] = useState("");
  const [complianceDescription, setComplianceDescription] = useState("");
  const [complianceCode, setComplianceCode] = useState("");
  const [statusOptions] = useState([
    { value: "Todo", label: "Todo" },
    { value: "In Progress", label: "In Progress" },
    { value: "Done", label: "Done" },
  ]);
  const [selectedStatus, setSelectedStatus] = useState("");

  const handleStatusSelection = (e) => {
    setSelectedStatus(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let fieldName = [];
    if (!complianceId) {
      fieldName.push("Compliance ID");
    }
    if (!complianceCode) {
      fieldName.push("COmpliance Code");
    }
    if (!selectedStatus) {
      fieldName.push("Selected Status");
    }
    if (!complianceDescription) {
      fieldName.push("Compliance Description");
    }
    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }
    if (isError) {
      return;
    }
    if (mode === "add") {
      addCompliance();
    } else {
      updateCompliance();
    }
  };
  const addCompliance = () => {
    const newCompliance = {
      complianceId: complianceId,
      complianceCode: complianceCode,
      complianceDescription: complianceDescription,
      selectedStatus: selectedStatus,
    };
    setCompliances((prevCompliances) => [...prevCompliances, newCompliance]);
    clearFields();
  };
  const clearFields = () => {
    setComplianceId("");
    setComplianceCode("");
    setComplianceDescription("");
    setSelectedStatus("");
  };
  const editCompliance = (complianceId) => {
    setMode("edit");
    setActiveId(complianceId);
    const compliance = compliances.find(
      (compliance) => compliance.complianceId === complianceId
    );
    setComplianceId(compliance.complianceId);
    setComplianceCode(compliance.complianceCode);
    setComplianceDescription(compliance.complianceDescription);
    setSelectedStatus(compliance.selectedStatus);
  };
  const deleteCompliance = (complianceId) => {
    const newCompliances = compliances.filter(
      (compliance) => compliance.complianceId !== complianceId
    );
    setCompliances(newCompliances);
  };
  const updateCompliance = () => {
    const compliance = compliances.find(
      (compliance) => compliance.complianceId === activeId
    );
    compliance.complianceId = complianceId;
    compliance.complianceCode = complianceCode;
    compliance.complianceDescription = complianceDescription;
    compliance.selectedStatus = selectedStatus;
    clearFields();
    setMode("add");
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
          QS Compliance
        </h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form action="#">
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Compliance ID
                </label>
                <input
                  type="text"
                  name="compliance-id"
                  className="form-control"
                  onChange={(e) => setComplianceId(e.target.value)}
                  value={complianceId}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Compliance Code
                </label>
                <input
                  type="text"
                  name="compliance-code"
                  className="form-control"
                  onChange={(e) => setComplianceCode(e.target.value)}
                  value={complianceCode}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Compliance Description
                </label>
                <input
                  type="text"
                  name="compliance-Descriptiom"
                  className="form-control"
                  onChange={(e) => setComplianceDescription(e.target.value)}
                  value={complianceDescription}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">&nbsp;</label>
                <select
                  className="form-control"
                  name="status"
                  id="status"
                  value={selectedStatus}
                  onChange={handleStatusSelection}
                >
                  <option value="">Status</option>
                  {statusOptions.map((status) => (
                    <option key={status.value} value={status.value}>
                      {status.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
              <Link
                className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block"
                onClick={handleSubmit}
              >
                {mode === "add" ? "Add" : "Edit"} Compliance
              </Link>
            </div>
          </div>
        </form>
      </div>
      {compliances.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th className="bg-current text-white">Compliance ID</th>
                <th className="bg-current text-white">Compliance Code</th>
                <th className="bg-current text-white">
                  Compliance Description
                </th>
                <th className="bg-current text-white">Status</th>
                <th className="bg-current text-white">Actions</th>
              </tr>
            </thead>
            <tbody>
              {compliances.map((compliance) => (
                <tr key={compliance.complianceId}>
                  <td>{compliance.complianceId}</td>
                  <td>{compliance.complianceCode}</td>
                  <td>{compliance.complianceDescription}</td>
                  <td>{compliance.selectedStatus}</td>
                  <td className="text-center">
                    <i
                      className="feather-edit text-current mr-sm-2 mr-md-3 mr-lg-4"
                      style={{ cursor: "pointer" }}
                      onClick={() => editCompliance(compliance.complianceId)}
                    />
                    <i
                      className="feather-trash text-red"
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteCompliance(compliance.complianceId)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default QSCompliance;
