import { Link } from "react-router-dom";

function ManageInstructor() {
  return (
    <div className="row">
      <div className="col-xl-4 col-lg-6 col-md-6">
        <div className="card mb-4 d-block w-100 shadow-xss rounded-lg p-xxl-5 p-4 border-0 text-center">
          <Link
            to="/dashboard/instructor/create-courses"
            className="p-2 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current"
          >
            Course Management
          </Link>
          <Link
            to="/dashboard/instructor/course-content"
            className="p-2 mt-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current"
          >
            Course Content
          </Link>
          <Link
            to="/dashboard/instructor/create-exam"
            className="p-2 mt-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current"
          >
            Assessment Creation
          </Link>
          <Link
            to="/dashboard/instructor/grade-students"
            className="p-2 mt-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current"
          >
            Grade Students
          </Link>
          {/* <Link
            to="/dashboard/instructor/student-progress-monitoring"
            className="p-2 mt-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current"
          >
            Student Progress Monitoring
          </Link> */}
        </div>
      </div>
    </div>
  );
}

export default ManageInstructor;
