import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function StudentPerformanceData() {
  const navigate = useNavigate();
  let isError = false;
  const [id, setId] = useState(1);
  const [reportName, setReportName] = useState("");
  const [reportType, setReportType] = useState("");
  const [studentId, setStudentId] = useState("");
  const [performanceData, setPerformanceData] = useState([]);
  const handleSubmit = (e) => {
    e.preventDefault();

    let fieldName = [];
    if (!reportName.length) {
      fieldName.push("Report Name");
    }
    if (!reportType.length) {
      fieldName.push("Report Type");
    }
    if (!studentId.length) {
      fieldName.push("Student ID");
    }

    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }

    if (isError) {
      return;
    }
    generateReport();
  };
  const clearFields = () => {
    setReportName("");
    setReportType("");
    setStudentId("");
  };
  const generateReport = () => {
    const newPerformance = {
      id: id,
      reportName: reportName,
      reportType: reportType,
      studentId: studentId,
    };

    setPerformanceData((prevPerformanceData) => [
      ...prevPerformanceData,
      newPerformance,
    ]);
    console.log(newPerformance);

    clearFields();
    setId(id + 1);
  };
  function convertToCSV(performance) {
    const rows = [];
    const headers = Object.keys(performance);
    rows.push(headers.map((header) => `"${header}"`).join(",")); // Add headers

    const values = headers.map((header) => performance[header]);
    rows.push(values.map((value) => `"${value}"`).join(",")); // Add values

    return rows.join("\n");
  }
  function downloadCSV(csv, fileName) {
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
          Student Performance Data
        </h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form action="#">
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Report Name
                </label>
                <input
                  type="text"
                  name="report-name"
                  className="form-control"
                  onChange={(e) => setReportName(e.target.value)}
                  value={reportName}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Report Type
                </label>
                <input
                  type="text"
                  name="report-type"
                  className="form-control"
                  onChange={(e) => setReportType(e.target.value)}
                  value={reportType}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Student ID</label>
                <input
                  type="text"
                  name="student-id"
                  className="form-control"
                  onChange={(e) => setStudentId(e.target.value)}
                  value={studentId}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
              <Link
                className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block"
                type="submit"
                onClick={(e) => handleSubmit(e)}
              >
                Generate Report
              </Link>
            </div>
          </div>
        </form>
      </div>
      {performanceData.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th className="bg-current text-white">Report ID</th>
                <th className="bg-current text-white">Report Name</th>
                <th className="bg-current text-white">Student ID</th>
                <th className="bg-current text-white">Actions</th>
              </tr>
            </thead>
            <tbody>
              {performanceData.map((performance) => (
                <tr key={performance.id}>
                  <td>{performance.id}</td>
                  <td>{performance.reportName}</td>
                  <td>{performance.studentId}</td>

                  <td className="text-center">
                    <i
                      className="feather-download text-green"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const csvData = convertToCSV(performance);
                        downloadCSV(
                          csvData,
                          `performance_${performance.id}.csv`
                        );
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default StudentPerformanceData;
