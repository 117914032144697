import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";

function ManageStudent() {
  const exploreOptions = {
    items: 6,
    responsive: {
      0: {
        items: 1.5,
      },
      375: {
        items: 2,
      },
      600: {
        items: 4,
      },
      1024: {
        items: 4,
      },
      1440: {
        items: 7,
      },
    },
  };
  const courseOptions = {
    items: 3,
    responsive: {
      0: {
        items: 0.75,
      },
      375: {
        items: 0.9,
      },
      425: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1024: {
        items: 2,
      },
      1440: {
        items: 3.4,
      },
    },
  };
  const liveClassesOptions = {
    items: 1,
    responsive: {
      0: {
        items: 1,
      },
      375: {
        items: 1.25,
      },
      425: {
        items: 1.5,
      },
      600: {
        items: 3,
      },
      1024: {
        items: 3,
      },
      1440: {
        items: 5,
      },
    },
  };

  return (
    <div className="row">
      <div className="col-lg-12 pt-2">
        <h2 className="fw-400 font-lg">Explore</h2>
      </div>
      <div className="col-lg-12 mt-3 overflow-hidden">
        <OwlCarousel
          className="owl-carousel category-card owl-theme overflow-hidden overflow-visible-xl nav-none"
          {...exploreOptions}
        >
          <div className="item">
            <div
              className="card cat-card-hover mr-1 w140 border-0 p-4 rounded-lg text-center"
              style={{ backgroundColor: "#fcf1eb" }}
            >
              <Link to="/dashboard/student/course-details">
                <div className="card-body p-2 ml-1">
                  <span className="btn-round-xl bg-white">
                    <img
                      src="/images/download1.png"
                      alt="icon"
                      className="p-2"
                    />
                  </span>
                  <h4 className="fw-600 font-xsss mt-3 mb-0">
                    Bootstrap
                    <span className="d-block font-xsssss fw-500 text-grey-500 mt-2">
                      32 Course
                    </span>
                  </h4>
                </div>
              </Link>
            </div>
          </div>
          <div className="item">
            <div
              className="card cat-card-hover mr-1 w140 border-0 p-4 rounded-lg text-center"
              style={{ backgroundColor: "#fff9eb" }}
            >
              <Link to="/dashboard/student/course-details">
                <div className="card-body p-2 ml-1">
                  <span className="btn-round-xl bg-white">
                    <img
                      src="/images/download2.png"
                      alt="icon"
                      className="p-2"
                    />
                  </span>
                  <h4 className="fw-600 font-xsss mt-3 mb-0">
                    HTML
                    <span className="d-block font-xsssss fw-500 text-grey-500 mt-2">
                      32 Course
                    </span>
                  </h4>
                </div>
              </Link>
            </div>
          </div>
          <div className="item">
            <div
              className="card cat-card-hover mr-1 w140 border-0 p-4 rounded-lg text-center"
              style={{ backgroundColor: "#e5f4fb" }}
            >
              <Link to="/dashboard/student/course-details">
                <div className="card-body p-2 ml-1">
                  <span className="btn-round-xl bg-white">
                    <img
                      src="/images/download3.png"
                      alt="icon"
                      className="p-2"
                    />
                  </span>
                  <h4 className="fw-600 font-xsss mt-3 mb-0">
                    Jquery
                    <span className="d-block font-xsssss fw-500 text-grey-500 mt-2">
                      32 Course
                    </span>
                  </h4>
                </div>
              </Link>
            </div>
          </div>
          <div className="item">
            <div
              className="card cat-card-hover mr-1 w140 border-0 p-4 rounded-lg text-center"
              style={{ backgroundColor: "#dcf4f8" }}
            >
              <Link to="/dashboard/student/course-details">
                <div className="card-body p-2 ml-1">
                  <span className="btn-round-xl bg-white">
                    <img
                      src="/images/download4.png"
                      alt="icon"
                      className="p-2"
                    />
                  </span>
                  <h4 className="fw-600 font-xsss mt-3 mb-0">
                    Sass
                    <span className="d-block font-xsssss fw-500 text-grey-500 mt-2">
                      32 Course
                    </span>
                  </h4>
                </div>
              </Link>
            </div>
          </div>
          <div className="item">
            <div
              className="card cat-card-hover mr-1 w140 border-0 p-4 rounded-lg text-center"
              style={{ backgroundColor: "#fcf1eb" }}
            >
              <Link to="/dashboard/student/course-details">
                <div className="card-body p-2 ml-1">
                  <span className="btn-round-xl bg-white">
                    <img
                      src="/images/download5.png"
                      alt="icon"
                      className="p-2"
                    />
                  </span>
                  <h4 className="fw-600 font-xsss mt-3 mb-0">
                    React
                    <span className="d-block font-xsssss fw-500 text-grey-500 mt-2">
                      32 Course
                    </span>
                  </h4>
                </div>
              </Link>
            </div>
          </div>
          <div className="item">
            <div
              className="card cat-card-hover mr-1 w140 border-0 p-4 rounded-lg text-center"
              style={{ backgroundColor: "#fff9eb" }}
            >
              <Link to="/dashboard/student/course-details">
                <div className="card-body p-2 ml-1">
                  <span className="btn-round-xl bg-white">
                    <img
                      src="/images/download6.png"
                      alt="icon"
                      className="p-2"
                    />
                  </span>
                  <h4 className="fw-600 font-xsss mt-3 mb-0">
                    JAVA
                    <span className="d-block font-xsssss fw-500 text-grey-500 mt-2">
                      32 Course
                    </span>
                  </h4>
                </div>
              </Link>
            </div>
          </div>
          <div className="item">
            <div
              className="card cat-card-hover mr-1 w140 border-0 p-4 rounded-lg text-center"
              style={{ backgroundColor: "#e5f4fb" }}
            >
              <Link to="/dashboard/student/course-details">
                <div className="card-body p-2 ml-1">
                  <span className="btn-round-xl bg-white">
                    <img
                      src="/images/download7.png"
                      alt="icon"
                      className="p-2"
                    />
                  </span>
                  <h4 className="fw-600 font-xsss mt-3 mb-0">
                    Python
                    <span className="d-block font-xsssss fw-500 text-grey-500 mt-2">
                      32 Course
                    </span>
                  </h4>
                </div>
              </Link>
            </div>
          </div>
          <div className="item">
            <div
              className="card cat-card-hover mr-1 w140 border-0 p-4 rounded-lg text-center"
              style={{ backgroundColor: "#fcf1eb" }}
            >
              <Link to="/dashboard/student/course-details">
                <div className="card-body p-2 ml-1">
                  <span className="btn-round-xl bg-white">
                    <img src="/images/mongodb.png" alt="icon" className="p-2" />
                  </span>
                  <h4 className="fw-600 font-xsss mt-3 mb-0">
                    Mongodb
                    <span className="d-block font-xsssss fw-500 text-grey-500 mt-2">
                      32 Course
                    </span>
                  </h4>
                </div>
              </Link>
            </div>
          </div>
          <div className="item">
            <div
              className="card cat-card-hover mr-1 w140 border-0 p-4 rounded-lg text-center"
              style={{ backgroundColor: "#fcf1eb" }}
            >
              <Link to="/dashboard/student/course-details">
                <div className="card-body p-2 ml-1">
                  <span className="btn-round-xl bg-white">
                    <img
                      src="/images/download1.png"
                      alt="icon"
                      className="p-2"
                    />
                  </span>
                  <h4 className="fw-600 font-xsss mt-3 mb-0">
                    Designer
                    <span className="d-block font-xsssss fw-500 text-grey-500 mt-2">
                      32 Course
                    </span>
                  </h4>
                </div>
              </Link>
            </div>
          </div>
          <div className="item">
            <div
              className="card cat-card-hover mr-1 w140 border-0 p-4 rounded-lg text-center"
              style={{ backgroundColor: "#fff9eb" }}
            >
              <Link to="/dashboard/student/course-details">
                <div className="card-body p-2 ml-1">
                  <span className="btn-round-xl bg-white">
                    <img
                      src="/images/download2.png"
                      alt="icon"
                      className="p-2"
                    />
                  </span>
                  <h4 className="fw-600 font-xsss mt-3 mb-0">
                    Designer
                    <span className="d-block font-xsssss fw-500 text-grey-500 mt-2">
                      32 Course
                    </span>
                  </h4>
                </div>
              </Link>
            </div>
          </div>
        </OwlCarousel>
      </div>
      <div className="col-lg-12 pt-4 mt-5 mb-3">
        <h2 className="fw-400 font-lg d-block">
          My <b> Courses</b>
        </h2>
      </div>
      <div className="col-lg-12 pt-2 overflow-hidden">
        <OwlCarousel
          className="owl-carousel category-card owl-theme overflow-hidden overflow-visible-xl nav-none"
          {...courseOptions}
        >
          <div className="item">
            <div className="card course-card w300 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 mb-4">
              <div className="card-image w-100 mb-3">
                <Link
                  to="/dashboard/student/course-details"
                  className="position-relative d-block"
                >
                  <img src="/images/v-1.png" alt="v-1" className="w-100" />
                </Link>
              </div>
              <div className="card-body pt-0">
                <span className="font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 alert-warning d-inline-block text-warning mr-1">
                  Python
                </span>
                <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                  <Link
                    to="/dashboard/student/course-details"
                    className="text-dark text-grey-900"
                  >
                    Complete Python Bootcamp From Zero to Hero in Python
                  </Link>
                </h4>
                <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                  32 Lessons
                </h6>
                <div className="progress">
                  <div
                    className="progress-bar bg-info"
                    role="progressbar"
                    style={{ width: "31%" }}
                    aria-valuenow="31"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    31%
                  </div>
                </div>
                {/* <h6 className="font-xssss fw-600 ml-0 mt-2">
                  Course Completed
                </h6> */}
              </div>
            </div>
          </div>
          <div className="item">
            <div className="card course-card w300 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 mb-4">
              <div className="card-image w-100 mb-3">
                <Link
                  to="/dashboard/student/course-details"
                  className="position-relative d-block"
                >
                  <img src="/images/v-2.png" alt="v-2" className="w-100" />
                </Link>
              </div>
              <div className="card-body pt-0">
                <span className="font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 alert-danger d-inline-block text-danger mr-1">
                  Designer
                </span>
                <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                  <Link
                    to="/dashboard/student/course-details"
                    className="text-dark text-grey-900"
                  >
                    Complete Python Bootcamp From Zero to Hero in Python
                  </Link>
                </h4>
                <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                  24 Lessons
                </h6>
                <div className="progress">
                  <div
                    className="progress-bar bg-info"
                    role="progressbar"
                    style={{ width: "23%" }}
                    aria-valuenow="23"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    23%
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="card course-card w300 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 mb-4">
              <div className="card-image w-100 mb-3">
                <Link
                  to="/dashboard/student/course-details"
                  className="position-relative d-block"
                >
                  <img src="/images/v-3.png" alt="v-3" className="w-100" />
                </Link>
              </div>
              <div className="card-body pt-0">
                <span className="font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 alert-success d-inline-block text-success mr-1">
                  Bootstrap
                </span>
                <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                  <Link
                    to="/dashboard/student/course-details"
                    className="text-dark text-grey-900"
                  >
                    Java Programming Masterclass for Developers
                  </Link>
                </h4>
                <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                  14 Lessons
                </h6>
                <div className="progress">
                  <div
                    className="progress-bar bg-info"
                    role="progressbar"
                    style={{ width: "52%" }}
                    aria-valuenow="52"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    52%
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="card course-card w300 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 mb-4">
              <div className="card-image w-100 mb-3">
                <Link
                  to="/dashboard/student/course-details"
                  className="position-relative d-block"
                >
                  <img src="/images/v-5.jpg" alt="v-5" className="w-100" />
                </Link>
              </div>
              <div className="card-body pt-0">
                <span className="font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 alert-danger d-inline-block text-danger mr-1">
                  Develop
                </span>
                <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                  <Link
                    to="/dashboard/student/course-details"
                    className="text-dark text-grey-900"
                  >
                    The Data Science Course Complete Data Science
                  </Link>
                </h4>
                <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                  23 Lessons
                </h6>
                <div className="progress">
                  <div
                    className="progress-bar bg-info"
                    role="progressbar"
                    style={{ width: "44%" }}
                    aria-valuenow="44"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    44%
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="card course-card w300 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 mb-4">
              <div className="card-image w-100 mb-3">
                <Link
                  to="/dashboard/student/course-details"
                  className="position-relative d-block"
                >
                  <img src="/images/v-9.jpg" alt="v-9" className="w-100" />
                </Link>
              </div>
              <div className="card-body pt-0">
                <span className="font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 alert-danger d-inline-block text-danger mr-1">
                  Develop
                </span>
                <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                  <Link
                    to="/dashboard/student/course-details"
                    className="text-dark text-grey-900"
                  >
                    The Data Science Course Complete Data Science
                  </Link>
                </h4>
                <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                  23 Lessons
                </h6>
                <div className="progress">
                  <div
                    className="progress-bar bg-info"
                    role="progressbar"
                    style={{ width: "73%" }}
                    aria-valuenow="73"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    73%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </div>
      <div className="col-lg-12 pt-4 mt-5 mb-3">
        <h2 className="fw-400 font-lg d-block">
          Popular <b> Courses</b>
        </h2>
      </div>
      <div className="col-lg-12 pt-2 overflow-hidden">
        <OwlCarousel
          className="owl-carousel category-card owl-theme overflow-hidden overflow-visible-xl nav-none"
          {...courseOptions}
        >
          <div className="item">
            <div className="card course-card w300 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 mb-4">
              <div className="card-image w-100 mb-3">
                <Link
                  to="/dashboard/student/course-details"
                  className="video-bttn position-relative d-block"
                >
                  <img src="/images/v-1.png" alt="v-1" className="w-100" />
                </Link>
              </div>
              <div className="card-body pt-0">
                <span className="font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 alert-warning d-inline-block text-warning mr-1">
                  Python
                </span>
                <span className="font-xss fw-700 pl-3 pr-3 ls-2 lh-32 d-inline-block text-success float-right">
                  <span className="font-xsssss">$</span> 240
                </span>
                <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                  <Link
                    to="/dashboard/student/course-details"
                    className="text-dark text-grey-900"
                  >
                    Complete Python Bootcamp From Zero to Hero in Python
                  </Link>
                </h4>
                <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                  32 Lessons
                </h6>
                <ul className="memberlist mt-3 mb-2 ml-0 d-block">
                  <li>
                    <Link>
                      <img
                        src="/images/user-6.png"
                        alt="user"
                        className="w30 d-inline-block"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <img
                        src="/images/user-7.png"
                        alt="user"
                        className="w30 d-inline-block"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <img
                        src="/images/user-8.png"
                        alt="user"
                        className="w30 d-inline-block"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <img
                        src="/images/user-3.png"
                        alt="user"
                        className="w30 d-inline-block"
                      />
                    </Link>
                  </li>
                  <li className="last-member">
                    <Link className="bg-greylight fw-600 text-grey-500 font-xssss ls-3 text-center">
                      +2
                    </Link>
                  </li>
                  <li className="pl-4 w-auto">
                    <Link className="fw-500 text-grey-500 font-xssss">
                      Students applied
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="card course-card w300 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 mb-4">
              <div className="card-image w-100 mb-3">
                <Link
                  to="/dashboard/student/course-details"
                  className="video-bttn position-relative d-block"
                >
                  <img src="/images/v-2.png" alt="v-2" className="w-100" />
                </Link>
              </div>
              <div className="card-body pt-0">
                <span className="font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 alert-danger d-inline-block text-danger mr-1">
                  Designer
                </span>
                <span className="font-xss fw-700 pl-3 pr-3 ls-2 lh-32 d-inline-block text-success float-right">
                  <span className="font-xsssss">$</span> 40
                </span>
                <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                  <Link
                    to="/dashboard/student/course-details"
                    className="text-dark text-grey-900"
                  >
                    Complete Python Bootcamp From Zero to Hero in Python
                  </Link>
                </h4>
                <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                  24 Lessons
                </h6>
                <ul className="memberlist mt-3 mb-2 ml-0 d-block">
                  <li>
                    <Link>
                      <img
                        src="/images/user-6.png"
                        alt="user"
                        className="w30 d-inline-block"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <img
                        src="/images/user-7.png"
                        alt="user"
                        className="w30 d-inline-block"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <img
                        src="/images/user-8.png"
                        alt="user"
                        className="w30 d-inline-block"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <img
                        src="/images/user-3.png"
                        alt="user"
                        className="w30 d-inline-block"
                      />
                    </Link>
                  </li>
                  <li className="last-member">
                    <Link className="bg-greylight fw-600 text-grey-500 font-xssss ls-3 text-center">
                      +2
                    </Link>
                  </li>
                  <li className="pl-4 w-auto">
                    <Link className="fw-500 text-grey-500 font-xssss">
                      Students applied
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="card course-card w300 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 mb-4">
              <div className="card-image w-100 mb-3">
                <Link
                  to="/dashboard/student/course-details"
                  className="video-bttn position-relative d-block"
                >
                  <img src="/images/v-3.png" alt="v-3" className="w-100" />
                </Link>
              </div>
              <div className="card-body pt-0">
                <span className="font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 alert-success d-inline-block text-success mr-1">
                  Bootstrap
                </span>
                <span className="font-xss fw-700 pl-3 pr-3 ls-2 lh-32 d-inline-block text-success float-right">
                  <span className="font-xsssss">$</span> 60
                </span>
                <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                  <Link
                    to="/dashboard/student/course-details"
                    className="text-dark text-grey-900"
                  >
                    Java Programming Masterclass for Developers
                  </Link>
                </h4>
                <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                  14 Lessons
                </h6>
                <ul className="memberlist mt-3 mb-2 ml-0 d-block">
                  <li>
                    <Link>
                      <img
                        src="/images/user-6.png"
                        alt="user"
                        className="w30 d-inline-block"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <img
                        src="/images/user-7.png"
                        alt="user"
                        className="w30 d-inline-block"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <img
                        src="/images/user-8.png"
                        alt="user"
                        className="w30 d-inline-block"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <img
                        src="/images/user-3.png"
                        alt="user"
                        className="w30 d-inline-block"
                      />
                    </Link>
                  </li>
                  <li className="last-member">
                    <Link className="bg-greylight fw-600 text-grey-500 font-xssss ls-3 text-center">
                      +2
                    </Link>
                  </li>
                  <li className="pl-4 w-auto">
                    <Link className="fw-500 text-grey-500 font-xssss">
                      Students applied
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="card course-card w300 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 mb-4">
              <div className="card-image w-100 mb-3">
                <Link
                  to="/dashboard/student/course-details"
                  className="video-bttn position-relative d-block"
                >
                  <img src="/images/v-5.jpg" alt="v-5" className="w-100" />
                </Link>
              </div>
              <div className="card-body pt-0">
                <span className="font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 alert-danger d-inline-block text-danger mr-1">
                  Develop
                </span>
                <span className="font-xss fw-700 pl-3 pr-3 ls-2 lh-32 d-inline-block text-success float-right">
                  <span className="font-xsssss">$</span> 370
                </span>
                <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                  <Link
                    to="/dashboard/student/course-details"
                    className="text-dark text-grey-900"
                  >
                    The Data Science Course Complete Data Science
                  </Link>
                </h4>
                <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                  23 Lessons
                </h6>
                <ul className="memberlist mt-3 mb-2 ml-0 d-block">
                  <li>
                    <Link>
                      <img
                        src="/images/user-6.png"
                        alt="user"
                        className="w30 d-inline-block"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <img
                        src="/images/user-7.png"
                        alt="user"
                        className="w30 d-inline-block"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <img
                        src="/images/user-8.png"
                        alt="user"
                        className="w30 d-inline-block"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <img
                        src="/images/user-3.png"
                        alt="user"
                        className="w30 d-inline-block"
                      />
                    </Link>
                  </li>
                  <li className="last-member">
                    <Link className="bg-greylight fw-600 text-grey-500 font-xssss ls-3 text-center">
                      +2
                    </Link>
                  </li>
                  <li className="pl-4 w-auto">
                    <Link className="fw-500 text-grey-500 font-xssss">
                      Students applied
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="card course-card w300 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 mb-4">
              <div className="card-image w-100 mb-3">
                <Link
                  to="/dashboard/student/course-details"
                  className="video-bttn position-relative d-block"
                >
                  <img src="/images/v-9.jpg" alt="v-9" className="w-100" />
                </Link>
              </div>
              <div className="card-body pt-0">
                <span className="font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 alert-danger d-inline-block text-danger mr-1">
                  Develop
                </span>
                <span className="font-xss fw-700 pl-3 pr-3 ls-2 lh-32 d-inline-block text-success float-right">
                  <span className="font-xsssss">$</span> 370
                </span>
                <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                  <Link
                    to="/dashboard/student/course-details"
                    className="text-dark text-grey-900"
                  >
                    The Data Science Course Complete Data Science
                  </Link>
                </h4>
                <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                  23 Lessons
                </h6>
                <ul className="memberlist mt-3 mb-2 ml-0 d-block">
                  <li>
                    <Link>
                      <img
                        src="/images/user-6.png"
                        alt="user"
                        className="w30 d-inline-block"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <img
                        src="/images/user-7.png"
                        alt="user"
                        className="w30 d-inline-block"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <img
                        src="/images/user-8.png"
                        alt="user"
                        className="w30 d-inline-block"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <img
                        src="/images/user-3.png"
                        alt="user"
                        className="w30 d-inline-block"
                      />
                    </Link>
                  </li>
                  <li className="last-member">
                    <Link className="bg-greylight fw-600 text-grey-500 font-xssss ls-3 text-center">
                      +2
                    </Link>
                  </li>
                  <li className="pl-4 w-auto">
                    <Link className="fw-500 text-grey-500 font-xssss">
                      Students applied
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </div>
      <div className="col-lg-12 pt-0 mt-5 mb-3">
        <h2 className="fw-400 font-lg d-block">
          Live <b> Classes</b>
        </h2>
      </div>
      <div className="col-lg-12 pt-2 overflow-hidden">
        <OwlCarousel
          className="owl-carousel category-card owl-theme overflow-hidden overflow-visible-xl nav-none"
          {...liveClassesOptions}
        >
          <div className="item">
            <div className="card w200 d-block border-0 shadow-xss rounded-lg overflow-hidden mb-4">
              <div
                className="card-body position-relative h100 bg-gradiant-bottom bg-image-cover bg-image-center"
                style={{ backgroundImage: "url(images/c-3.png)" }}
              />
              <div className="card-body d-block w-100 pl-4 pr-4 pb-4 text-center">
                <figure className="avatar ml-auto mr-auto mb-0 mt--6 position-relative w75 z-index-1">
                  <img
                    src="/images/user-11.png"
                    alt="user-11"
                    className="float-right p-1 bg-white rounded-circle w-100"
                  />
                </figure>
                <div className="clearfix" />
                <h4 className="fw-700 font-xsss mt-3 mb-1">Rene Tremblay</h4>
                <p className="fw-500 font-xsssss text-grey-500 mt-0 mb-2">
                  support@gmail.com
                </p>
                <Link
                  to="/dashboard/livestream"
                  className="live-tag mt-2 mb-3 bg-danger p-2 z-index-1 rounded-lg text-white font-xsssss text-uppersace fw-700 ls-3"
                >
                  LIVE
                </Link>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="card w200 d-block border-0 shadow-xss rounded-lg overflow-hidden mb-4">
              <div
                className="card-body position-relative h100 bg-gradiant-bottom bg-image-cover bg-image-center"
                style={{ backgroundImage: "url(images/c-6.png)" }}
              />
              <div className="card-body d-block w-100 pl-4 pr-4 pb-4 text-center">
                <figure className="avatar ml-auto mr-auto mb-0 mt--6 position-relative w75 z-index-1">
                  <img
                    src="/images/user-2.png"
                    alt="user-2"
                    className="float-right p-1 bg-white rounded-circle w-100"
                  />
                </figure>
                <div className="clearfix" />
                <h4 className="fw-700 font-xsss mt-3 mb-1">Jaunita Lebsack</h4>
                <p className="fw-500 font-xsssss text-grey-500 mt-0 mb-2">
                  support@gmail.com
                </p>
                <Link
                  to="/dashboard/livestream"
                  className="live-tag mt-2 mb-3 bg-danger p-2 z-index-1 rounded-lg text-white font-xsssss text-uppersace fw-700 ls-3"
                >
                  LIVE
                </Link>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="card w200 d-block border-0 shadow-xss rounded-lg overflow-hidden mb-4">
              <div
                className="card-body position-relative h100 bg-gradiant-bottom bg-image-cover bg-image-center"
                style={{ backgroundImage: "url(images/bb-7.jpg)" }}
              />
              <div className="card-body d-block w-100 pl-4 pr-4 pb-4 text-center">
                <figure className="avatar ml-auto mr-auto mb-0 mt--6 position-relative w75 z-index-1">
                  <img
                    src="/images/user-3.png"
                    alt="user-3"
                    className="float-right p-1 bg-white rounded-circle w-100"
                  />
                </figure>
                <div className="clearfix" />
                <h4 className="fw-700 font-xsss mt-3 mb-1">Maureen Ward</h4>
                <p className="fw-500 font-xsssss text-grey-500 mt-0 mb-2">
                  support@gmail.com
                </p>
                <Link
                  to="/dashboard/livestream"
                  className="live-tag mt-2 mb-3 bg-danger p-2 z-index-1 rounded-lg text-white font-xsssss text-uppersace fw-700 ls-3"
                >
                  LIVE
                </Link>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="card w200 d-block border-0 shadow-xss rounded-lg overflow-hidden mb-4">
              <div
                className="card-body position-relative h100 bg-gradiant-bottom bg-image-cover bg-image-center"
                style={{ backgroundImage: "url(images/bb-16.png)" }}
              />
              <div className="card-body d-block w-100 pl-4 pr-4 pb-4 text-center">
                <figure className="avatar ml-auto mr-auto mb-0 mt--6 position-relative w75 z-index-1">
                  <img
                    src="/images/user-4.png"
                    alt="user-4"
                    className="float-right p-1 bg-white rounded-circle w-100"
                  />
                </figure>
                <div className="clearfix" />
                <h4 className="fw-700 font-xsss mt-3 mb-1">
                  Josephine Hagenes
                </h4>
                <p className="fw-500 font-xsssss text-grey-500 mt-0 mb-2">
                  support@gmail.com
                </p>
                <Link
                  to="/dashboard/livestream"
                  className="live-tag mt-2 mb-3 bg-danger p-2 z-index-1 rounded-lg text-white font-xsssss text-uppersace fw-700 ls-3"
                >
                  LIVE
                </Link>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="card w200 d-block border-0 shadow-xss rounded-lg overflow-hidden mb-4">
              <div
                className="card-body position-relative h100 bg-gradiant-bottom bg-image-cover bg-image-center"
                style={{ backgroundImage: "url(images/bb-18.png)" }}
              />
              <div className="card-body d-block w-100 pl-4 pr-4 pb-4 text-center">
                <figure className="avatar ml-auto mr-auto mb-0 mt--6 position-relative w75 z-index-1">
                  <img
                    src="/images/user-7.png"
                    alt="user-7"
                    className="float-right p-1 bg-white rounded-circle w-100"
                  />
                </figure>
                <div className="clearfix" />
                <h4 className="fw-700 font-xsss mt-3 mb-1">Nikita Schumm</h4>
                <p className="fw-500 font-xsssss text-grey-500 mt-0 mb-2">
                  support@gmail.com
                </p>
                <Link
                  to="/dashboard/livestream"
                  className="live-tag mt-2 mb-3 bg-danger p-2 z-index-1 rounded-lg text-white font-xsssss text-uppersace fw-700 ls-3"
                >
                  LIVE
                </Link>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="card w200 d-block border-0 shadow-xss rounded-lg overflow-hidden mb-4">
              <div
                className="card-body position-relative h100 bg-gradiant-bottom bg-image-cover bg-image-center"
                style={{ backgroundImage: "url(images/bg-57.jpg)" }}
              />
              <div className="card-body d-block w-100 pl-4 pr-4 pb-4 text-center">
                <figure className="avatar ml-auto mr-auto mb-0 mt--6 position-relative w75 z-index-1">
                  <img
                    src="/images/user-5.png"
                    alt="user-5"
                    className="float-right p-1 bg-white rounded-circle w-100"
                  />
                </figure>
                <div className="clearfix" />
                <h4 className="fw-700 font-xsss mt-3 mb-1">
                  Cletus Rutherford
                </h4>
                <p className="fw-500 font-xsssss text-grey-500 mt-0 mb-2">
                  support@gmail.com
                </p>
                <Link
                  to="/dashboard/livestream"
                  className="live-tag mt-2 mb-3 bg-danger p-2 z-index-1 rounded-lg text-white font-xsssss text-uppersace fw-700 ls-3"
                >
                  LIVE
                </Link>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="card w200 d-block border-0 shadow-xss rounded-lg overflow-hidden mb-4">
              <div
                className="card-body position-relative h100 bg-gradiant-bottom bg-image-cover bg-image-center"
                style={{ backgroundImage: "url(images/bb-9.jpg)" }}
              />
              <div className="card-body d-block w-100 pl-4 pr-4 pb-4 text-center">
                <figure className="avatar ml-auto mr-auto mb-0 mt--6 position-relative w75 z-index-1">
                  <img
                    src="/images/user-6.png"
                    alt="user-6"
                    className="float-right p-1 bg-white rounded-circle w-100"
                  />
                </figure>
                <div className="clearfix" />
                <h4 className="fw-700 font-xsss mt-3 mb-1">Lottie Rodriguez</h4>
                <p className="fw-500 font-xsssss text-grey-500 mt-0 mb-2">
                  support@gmail.com
                </p>
                <Link
                  to="/dashboard/livestream"
                  className="live-tag mt-2 mb-3 bg-danger p-2 z-index-1 rounded-lg text-white font-xsssss text-uppersace fw-700 ls-3"
                >
                  LIVE
                </Link>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </div>
    </div>
  );
}

export default ManageStudent;
