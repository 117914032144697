import env from "../../../env";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import validator from "validator";
import axios from "axios";
import useAuth from "../../../hooks/useAuth";

export default function Login() {
  const navigate = useNavigate();
  const baseUrl = env.BASE_URL;

  const { setAuth } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  let isError = false;
  const handleSubmit = (e) => {
    e.preventDefault();

    let fieldName = [];
    if (!email.length) {
      fieldName.push("Email");
    }
    if (!password.length) {
      fieldName.push("Password ");
    }
    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }

    if (email.length && !validator.isEmail(email)) {
      toast.error("Email is Invalid!");
      isError = true;
    }

    if (isError) {
      return;
    }
    loginUser();
  };

  function loginUser() {
    axios
      .post(baseUrl + "/login", {
        email: email,
        password: password,
      })
      .then((response) => {
        const data = response.data;
        localStorage.setItem("name", data.name);
        localStorage.setItem("token", data.token);
        localStorage.setItem("role", data.role);
        localStorage.setItem("user", JSON.stringify(data));
        setAuth({
          name: `${data.name}`,
          token: `${data.token}`,
          role: `${data.role}`,
        });
        toast.success("Logged In Successfully!");
        navigate("/dashboard/" + data.role, { replace: true });
      })
      .catch(function (error) {
        toast.error(error.response?.data?.message);
      });
  }

  return (
    <div className="row">
      <div
        className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
        style={{ backgroundImage: "url(/images/login-bg.jpg)" }}
      />
      <div className="col-xl-7 vh-lg-100 align-items-center d-flex bg-white rounded-lg overflow-hidden">
        <div className="card shadow-none border-0 ml-auto mr-auto login-card">
          <div className="card-body rounded-0 text-left">
            <h2 className="fw-700 display1-size display2-md-size mb-3">
              Login
            </h2>
            <form>
              <div className="form-group icon-input mb-3">
                <i className="font-sm ti-email text-grey-500 pr-0" />
                <input
                  type="text"
                  className="style2-input pl-5 form-control text-grey-900 font-xsss fw-600"
                  placeholder="Email Address"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
              <div className="form-group icon-input mb-1">
                <input
                  type="Password"
                  className="style2-input pl-5 form-control text-grey-900 font-xss ls-3"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
                <i className="font-sm ti-lock text-grey-500 pr-0" />
              </div>
              <div className="form-check text-left mb-3 mt-2">
                <label
                  className="form-check-label font-xsss text-grey-500"
                  htmlFor="exampleCheck1"
                ></label>
                <Link
                  to="/auth/forgot-password"
                  className="fw-600 font-xsss text-grey-700 mt-1 float-right"
                >
                  Forgot your Password?
                </Link>
              </div>
            </form>
            <div className="col-sm-12 p-0 text-left">
              <div className="form-group mb-1">
                <Link
                  className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0"
                  onClick={(e) => handleSubmit(e)}
                >
                  Login
                </Link>
              </div>
              <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                Don't have account
                <Link to="/auth/register" className="fw-700 ml-1">
                  Register
                </Link>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
