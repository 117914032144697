import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Reports() {
  const navigate = useNavigate();
  let isError = false;
  const [id, setId] = useState(1);
  const [reports, setReports] = useState([]);
  const [reportName, setReportName] = useState("");
  const [reportType, setReportType] = useState("");
  const [studentId, setStudentId] = useState("");
  const [instructorId, setInstructorId] = useState("");
  const [coordianatorId, setCoordinatorId] = useState("");
  const [qaOfficerId, setQaOfficerId] = useState("");

  const getUserRole = () => {
    return localStorage.getItem("userRole");
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    let fieldName = [];
    if (!reportName.length) {
      fieldName.push("Report Name");
    }
    if (!reportType.length) {
      fieldName.push("Report Type");
    }
    if (!studentId.length) {
      fieldName.push("Student ID");
    }
    if (!instructorId.length) {
      fieldName.push("Instructor ID");
    }
    if (!coordianatorId.length) {
      fieldName.push("Coordinator ID");
    }
    if (!qaOfficerId.length && userRole === "admin") {
      fieldName.push("QA Officer ID");
    }

    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }

    if (isError) {
      return;
    }
    generateReport();
  };
  function convertToCSV(report) {
    const rows = [];
    const headers = Object.keys(report);
    rows.push(headers.map((header) => `"${header}"`).join(","));

    const values = headers.map((header) => report[header]);
    rows.push(values.map((value) => `"${value}"`).join(","));

    return rows.join("\n");
  }
  function downloadCSV(csv, fileName) {
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const clearFields = () => {
    setReportName("");
    setReportType("");
    setStudentId("");
    setCoordinatorId("");
    setQaOfficerId("");
    setInstructorId("");
  };
  const userRole = getUserRole();
  const generateReport = () => {
    const newReport = {
      id: id,
      name: reportName,
      type: reportType,
      studentId: studentId,
      coordinatorId: coordianatorId,
      qaOfficerId: qaOfficerId,
      instructorId: instructorId,
    };

    setReports((prevReports) => [...prevReports, newReport]);

    clearFields();
    setId(id + 1);
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">Reports</h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Report Name
                </label>
                <input
                  type="text"
                  name="report-name"
                  className="form-control"
                  onChange={(e) => setReportName(e.target.value)}
                  value={reportName}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Report Type
                </label>
                <input
                  type="text"
                  name="report-tyoe"
                  className="form-control"
                  onChange={(e) => setReportType(e.target.value)}
                  value={reportType}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Student ID</label>
                <input
                  type="text"
                  name="student-id"
                  className="form-control"
                  onChange={(e) => setStudentId(e.target.value)}
                  value={studentId}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Instructor ID
                </label>
                <input
                  type="text"
                  name="instructor-id"
                  className="form-control"
                  onChange={(e) => setInstructorId(e.target.value)}
                  value={instructorId}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Coordinator ID
                </label>
                <input
                  type="text"
                  name="coordiantor-id"
                  className="form-control"
                  onChange={(e) => setCoordinatorId(e.target.value)}
                  value={coordianatorId}
                />
              </div>
            </div>
            {userRole === "admin" && (
              <div className="col-lg-6 mb-3">
                <div className="form-group">
                  <label className="mont-font fw-600 font-xsss">
                    QA Officer ID
                  </label>
                  <input
                    type="text"
                    name="qa-officerId"
                    className="form-control"
                    onChange={(e) => setQaOfficerId(e.target.value)}
                    value={qaOfficerId}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-lg-12 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
              <Link
                className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block"
                type="submit"
                onClick={(e) => handleSubmit(e)}
              >
                Generate Report
              </Link>
            </div>
          </div>
        </form>
      </div>
      {reports.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th className="bg-current text-white">Report ID</th>
                <th className="bg-current text-white">Report Name</th>
                <th className="bg-current text-white">Report Type</th>
                <th className="bg-current text-white">Student ID</th>
                <th className="bg-current text-white">Instructor ID</th>
                <th className="bg-current text-white">Coordinator ID</th>
                {userRole === "admin" && (
                  <th className="bg-current text-white">QA Officer ID</th>
                )}
                <th className="bg-current text-white">Actions</th>
              </tr>
            </thead>
            <tbody>
              {reports.map((report) => (
                <tr key={report.id}>
                  <td>{report.id}</td>
                  <td>{report.name}</td>
                  <td>{report.type}</td>
                  <td>{report.studentId}</td>
                  <td>{report.instructorId}</td>
                  <td>{report.coordinatorId}</td>
                  {userRole === "admin" && <td>{report.qaOfficerId}</td>}
                  <td className="text-center">
                    <i
                      className="feather-download text-green"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const csvData = convertToCSV(report);
                        downloadCSV(csvData, `report_${report.id}.csv`);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Reports;
