import { Link } from "react-router-dom";

export default function Header() {
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");

  return (
    <div className="header-wrapper pt-3 pb-3 shadow-xss">
      <div className="container">
        <div className="row">
          <div className="col-lg-9 navbar pt-0 pb-0">
            <Link to="/">
              <h1 className="fredoka-font ls-3 fw-700 text-current font-xxl">
                Acumenify
                <span className="d-block font-xsssss ls-1 text-grey-500 open-font">
                  Unleash Your Inner Genius
                </span>
              </h1>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav nav-menu float-none text-right">
                <li className="nav-item">
                  <Link className="nav-link" to="/about">
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/courses">
                    Our Courses
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact">
                    Contact Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/blog">
                    Blog
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          {token && role ? (
            <div className="col-lg-3 text-right d-none d-xl-block">
              <Link
                to={"/dashboard/" + role}
                className="header-btn bg-current fw-500 text-white font-xssss p-2 lh-32 text-center d-inline-block rounded-xl mt-1 mr-1 mont-font"
                style={{ height: "3rem", width: "10rem" }}
              >
                <i className="ti-user font-xs"></i>&nbsp; Dashboard
              </Link>
            </div>
          ) : (
            <div className="col-lg-3 text-right d-none d-lg-block">
              <Link
                to="/auth/login"
                className="header-btn bg-dark fw-500 text-white font-xssss p-2 lh-32 w100 text-center d-inline-block rounded-xl mt-1 mr-1 mont-font"
              >
                Login
              </Link>
              <Link
                to="/auth/register"
                className="header-btn bg-current fw-500 text-white font-xssss p-2 lh-32 w100 text-center d-inline-block rounded-xl mt-1 mont-font"
              >
                Register
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
