import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import validator from "validator";
import axios from "axios";
import env from "../../../../env";

function ManageUsers(props) {
  const navigate = useNavigate();
  const manage = props.manage;

  let isError = false;
  const [mode, setMode] = useState("add");
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({
    id: "",
    name: "",
    email: "",
    phone: "",
    address: "",
    image: "",
  });

  const baseUrl = env.BASE_URL;
  const token = localStorage.getItem("token");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      role: manage.split(" ")[0].toString().toLowerCase(),
    },
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(baseUrl + "/role-users", config);
      setUsers(response.data.users);
    } catch (error) {
      console.error("Error fetching users list:", error);
    }
  };

  useEffect(() => {
    if (!users.length) fetchData();
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    let fieldName = [];
    if (!user.name.length) {
      fieldName.push("Name");
    }
    if (!user.email.length) {
      fieldName.push("Email");
    }
    if (!user.phone.length) {
      fieldName.push("Phone");
    }
    if (!user.address.length) {
      fieldName.push("Address");
    }

    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }

    if (user.email.length && !validator.isEmail(user.email)) {
      toast.error("Email is Invalid!");
      isError = true;
    }

    const phoneNumberValidation = /^[+]?\d+$/;
    if (
      user.phone.length &&
      !validator.isMobilePhone(user.phone) &&
      !phoneNumberValidation.test(user.phone)
    ) {
      toast.error("Phone Number is Invalid!");
      isError = true;
    }

    if (isError) {
      return;
    }
    mode === "add" ? addUser() : updateUser();
  };

  const clearFields = () => {
    setUser({
      name: "",
      email: "",
      phone: "",
      address: "",
      image: "",
    });
  };

  const addUser = () => {
    const newUser = {
      ...user,
      password: "password",
      password_confirmation: "password",
    };

    setUsers((prevUsers) => [...prevUsers, newUser]);

    clearFields();
  };

  const editUser = (id) => {
    setMode("edit");
    const activeUser = users.find((user) => user.id === id);
    setUser({
      id: activeUser.id,
      name: activeUser.name,
      email: activeUser.email,
      phone: activeUser.phone,
      address: activeUser.address,
      image: activeUser.image,
    });
  };

  const deleteUser = (id) => {
    const newUsers = users.filter((user) => user.id !== id);
    setUsers(newUsers);
  };

  const updateUser = () => {
    const formData = new FormData();
    formData.append("name", user.name);
    formData.append("email", user.email);
    formData.append("phone", user.phone);
    formData.append("address", user.address);

    if (user.image instanceof File) {
      formData.append("image", user.image);
    }

    axios
      .post(baseUrl + "/user/" + user.id, formData, config)
      .then((response) => {
        toast.success(manage + " Updated Successfully!");
        fetchData();
        clearFields();
        setMode("add");
      })
      .catch(function (error) {
        const tempdata = error.response.data;

        if (tempdata.errors) {
          const errorKeys = Object.keys(tempdata.errors);
          for (const errorKey of errorKeys) {
            for (const error of tempdata.errors[errorKey]) {
              toast.error(`${error}`);
            }
          }
        }
      });
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
          Manage {manage}s
        </h4>
      </div>
      <div className="card-body p-lg-5 p-3 w-100 border-0 mb-0">
        <form name={"manage-" + manage}>
          <div className="row">
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Name</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  onChange={(e) => setUser({ ...user, name: e.target.value })}
                  value={user.name}
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Email</label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                  value={user.email}
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Phone Number (only digits)
                </label>
                <input
                  type="tel"
                  name="phone"
                  className="form-control"
                  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                  onChange={(e) => setUser({ ...user, phone: e.target.value })}
                  value={user.phone}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Address</label>
                <textarea
                  className="w-100 h100 style2-textarea p-3 form-control"
                  onChange={(e) =>
                    setUser({ ...user, address: e.target.value })
                  }
                  value={user.address}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-3">
              <div className="card border-0">
                <div className="card-body d-flex justify-content-between align-items-end p-0">
                  <div className="form-group mb-0 w-100">
                    <label className="mont-font fw-600 font-xsss">
                      Profile Picture (Optional)
                    </label>
                    {user.image && (
                      <figure className="avatar mb-2 mt-2 w100">
                        <img
                          src={
                            user.image instanceof File
                              ? URL.createObjectURL(user.image)
                              : user.image
                          }
                          alt="Profile"
                          className="shadow-sm rounded-lg w-100"
                        />
                      </figure>
                    )}
                    <input
                      type="file"
                      name="file"
                      id="file"
                      className="input-file"
                      onChange={(e) =>
                        setUser({ ...user, image: e.target.files[0] })
                      }
                    />
                    <label
                      htmlFor="file"
                      className="rounded-lg text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                    >
                      <i className="ti-cloud-down large-icon mr-3 d-block" />
                      <span className="js-fileName">
                        Upload an Image
                        <br />
                        (Supported Formats : jpeg,png,jpg,gif,svg | File size{" "}
                        {"<="} 2MB)
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
              <Link
                type="submit"
                className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block"
                onClick={(e) => handleSubmit(e)}
              >
                {mode === "add" ? "Add" : "Edit"} {manage}
              </Link>
            </div>
          </div>
        </form>
      </div>
      {users.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th className="bg-current text-white fw-400">{manage} ID</th>
                <th className="bg-current text-white fw-400">Name</th>
                <th className="bg-current text-white fw-400">Email</th>
                <th className="bg-current text-white fw-400">Phone</th>
                <th className="bg-current text-white fw-400">Address</th>
                <th className="bg-current text-white fw-400">
                  Profile Picture
                </th>
                <th className="bg-current text-white fw-400">Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.phone}</td>
                  <td>{user.address || "-"}</td>
                  <td>
                    {user.image ? (
                      <figure className="avatar mb-2 mt-2 w100">
                        <img
                          src={
                            user.image instanceof File
                              ? URL.createObjectURL(user.image)
                              : user.image
                          }
                          alt="Profile"
                          className="shadow-sm rounded-lg w-100"
                        />
                      </figure>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-center">
                    <i
                      className="feather-edit text-current mr-sm-2 mr-md-3 mr-lg-4"
                      style={{ cursor: "pointer" }}
                      onClick={() => editUser(user.id)}
                    />
                    <i
                      className="feather-trash text-red"
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteUser(user.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default ManageUsers;
