import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";

function ManageCourses(props) {
  const [studentName, setStudentName] = useState("");
  const [studentId, setStudentId] = useState("");
  const [courses] = useState([
    { value: "Operating Systems", label: "Operating Systems" },
    { value: "Networking", label: "Networking" },
    { value: "Algorithms", label: "Algorithms" },
    { value: "Compiler Design", label: "Compiler Design" },
    { value: "System Design", label: "System Design" },
  ]);

  const navigate = useNavigate();
  const [selectedCourses, setSelectedCourses] = useState([]);
  let isError = false;
  const [mode, setMode] = useState("add");

  const [activeId, setActiveId] = useState(-1);
  const [students, setStudents] = useState([]);
  const title = props.title;
  const role = props.role;
  const dis = props.dis;

  const handleCourseSelection = (selectedValues) => {
    setSelectedCourses(selectedValues);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let fieldName = [];
    if (!studentName.length) {
      fieldName.push(role + " ID");
    }
    if (!studentId.length) {
      fieldName.push("Name");
    }
    if (selectedCourses.length === 0) {
      fieldName.push("Selected Courses");
    }
    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }
    if (isError) {
      return;
    }
    if (mode === "add") {
      addStudentCourse();
    } else {
      updateStudentCourse();
    }
  };

  const clearFields = () => {
    setStudentName("");
    setStudentId("");
    setSelectedCourses([]);
  };

  const addStudentCourse = () => {
    const newStudent = {
      id: studentId,
      studentName: studentName,
      selectedCourses: selectedCourses,
    };
    setStudents((prevStudents) => [...prevStudents, newStudent]);
    clearFields();
  };

  const editStudentCourse = (id) => {
    setMode("edit");
    setActiveId(id);
    const student = students.find((student) => student.id === id);
    setStudentName(student.studentName);
    setStudentId(student.id);
    setSelectedCourses(student.selectedCourses);
  };

  const deleteStudentCourse = (id) => {
    const newStudents = students.filter((student) => student.id !== id);
    setStudents(newStudents);
    clearFields();
  };

  const updateStudentCourse = () => {
    const student = students.find((student) => student.id === activeId);
    student.studentName = studentName;
    student.id = studentId;
    student.selectedCourses = selectedCourses;
    clearFields();
    setMode("add");
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">{title}</h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form action="#">
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">{role} ID</label>
                <input
                  type="text"
                  name={role + "Id"}
                  className="form-control"
                  onChange={(e) => setStudentId(e.target.value)}
                  value={studentId}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Name</label>
                <input
                  type="text"
                  name="studentName"
                  className="form-control"
                  onChange={(e) => setStudentName(e.target.value)}
                  value={studentName}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Courses</label>
                <Select
                  isMulti
                  options={courses}
                  onChange={handleCourseSelection}
                  value={selectedCourses}
                  placeholder="Select Courses"
                />
              </div>
            </div>
            <div className="col-lg-6 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
              <Link
                className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block"
                onClick={(e) => handleSubmit(e)}
              >
                {mode === "add" ? "Add" : "Edit"} {dis}
              </Link>
            </div>
          </div>
        </form>
      </div>
      {students.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th className="bg-current text-white">{role} ID</th>
                <th className="bg-current text-white">Name</th>
                <th className="bg-current text-white">Courses Registered</th>
                <th className="bg-current text-white">Actions</th>
              </tr>
            </thead>
            <tbody>
              {students.map((student) => (
                <tr key={student.id}>
                  <td>{student.id}</td>
                  <td>{student.studentName}</td>
                  <td>
                    {student.selectedCourses
                      .map((course) => course.label)
                      .join(", ")}
                  </td>
                  <td className="text-center">
                    <i
                      className="feather-edit text-current mr-sm-2 mr-md-3 mr-lg-4"
                      style={{ cursor: "pointer" }}
                      onClick={() => editStudentCourse(student.id)}
                    />
                    <i
                      className="feather-trash text-red"
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteStudentCourse(student.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default ManageCourses;
