import { Link, useNavigate } from "react-router-dom";
import ApexCharts from "react-apexcharts";

export default function Analytics() {
  const navigate = useNavigate();

  const borderColor = "#E4E4E4";

  const newCoursesOptions = setBarChartOptions(borderColor);
  const newUsersOptions = setBarChartOptions(borderColor);
  const coursesPurchasedOptions = setBarChartOptions(borderColor);

  function setBarChartOptions(color) {
    return {
      chart: {
        id: "basic-bar",
        type: "bar",
        height: "50%",
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May"],
        axisBorder: {
          show: false,
          color: color,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      grid: {
        borderColor: color,
        strokeDashArray: 0,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      stroke: {
        curve: "smooth",
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          columnWidth: "80%",
        },
      },
      states: {
        hover: {
          filter: {
            type: "darken",
            value: 0.9,
          },
        },
      },
      legend: {
        show: false,
      },
    };
  }

  const newCoursesSeries = [
    {
      name: "New Courses",
      data: [18, 34, 44, 52, 38],
    },
  ];
  const newUsersSeries = [
    {
      name: "New Users",
      data: [35, 66, 34, 56, 18],
    },
  ];
  const coursesPurchasedSeries = [
    {
      name: "Courses Purchased",
      data: [25, 58, 66, 19, 48],
    },
  ];

  const userOptions = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    chart: {
      type: "bar",
      height: "250%",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },

    legend: {
      show: false,
    },
    fill: {
      opacity: 1,
    },
  };

  const userSeries = [
    {
      name: "",
      data: [35, 66, 34, 56, 18, 35, 66, 34, 56, 18, 56, 18],
    },
    {
      name: "",
      data: [12, 34, 12, 11, 7, 12, 34, 12, 11, 7, 11, 7],
    },
  ];

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">Analytics</h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0 bg-lightgrey">
        <div className="row">
          <div className="col-xl-4 col-lg-12">
            <div className="card w-100 p-1 border-0 mt-4 rounded-lg bg-white shadow-xs overflow-hidden">
              <div className="card-body p-4">
                <div className="row">
                  <div className="col-7">
                    <h4 className="fw-700 text-success font-xssss mt-0 mb-0">
                      +45%
                    </h4>
                    <h2 className="text-grey-900 fw-700 display1-size mt-2 mb-2 ls-3 lh-1">
                      45
                    </h2>
                    <h4 className="fw-700 text-grey-500 font-xsssss ls-3 text-uppercase mb-0 mt-0">
                      New Courses
                    </h4>
                  </div>
                  <div className="col-5 text-left">
                    <ApexCharts
                      options={newCoursesOptions}
                      series={newCoursesSeries}
                      type="bar"
                      height={"50%"}
                      width={"100%"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-12">
            <div className="card w-100 p-1 border-0 mt-4 rounded-lg bg-white shadow-xs overflow-hidden">
              <div className="card-body p-4">
                <div className="row">
                  <div className="col-7">
                    <h4 className="fw-700 text-success font-xssss mt-0 mb-0">
                      -27%
                    </h4>
                    <h2 className="text-grey-900 fw-700 display1-size mt-2 mb-2 ls-3 lh-1">
                      25
                    </h2>
                    <h4 className="fw-700 text-grey-500 font-xsssss ls-3 text-uppercase mb-0 mt-0">
                      New Users
                    </h4>
                  </div>
                  <div className="col-5 text-left">
                    <ApexCharts
                      options={newUsersOptions}
                      series={newUsersSeries}
                      type="bar"
                      height={"50%"}
                      width={"100%"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-12">
            <div className="card w-100 p-1 border-0 mt-4 rounded-lg bg-white shadow-xs overflow-hidden">
              <div className="card-body p-4">
                <div className="row">
                  <div className="col-7">
                    <h4 className="fw-700 text-success font-xssss mt-0 mb-0">
                      -15%
                    </h4>
                    <h2 className="text-grey-900 fw-700 display1-size mt-2 mb-2 ls-3 lh-1">
                      445
                    </h2>
                    <h4 className="fw-700 text-grey-500 font-xsssss ls-3 text-uppercase mb-0 mt-0">
                      Courses Purchased
                    </h4>
                  </div>
                  <div className="col-5 text-left">
                    <ApexCharts
                      options={coursesPurchasedOptions}
                      series={coursesPurchasedSeries}
                      type="bar"
                      height={"50%"}
                      width={"100%"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfx" />
          <div className="col-lg-12">
            <div className="card w-100 p-3 border-0 mt-4 rounded-lg bg-white shadow-xs overflow-hidden">
              <ApexCharts
                options={userOptions}
                series={userSeries}
                type="bar"
                height={"200%"}
              />
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 d-flex">
            <div className="card w-100 p-3 border-0 mt-4 rounded-lg bg-white shadow-xs overflow-hidden">
              <div className="row mt-2">
                <div className="col-6 mb-1 text-center">
                  <h2 className="font-xl text-grey-900 fw-700 ls-lg">440</h2>
                  <h4 className="text-grey-500 d-flex justify-content-center fw-600 ls-lg font-xsssss text-uppercase">
                    <span className="mr-2 bg-facebook btn-round-xss d-inline-block mt-0 rounded-circle" />
                    Users this week
                  </h4>
                </div>
                <div className="col-6 mb-1 text-center">
                  <h2 className="font-xl text-grey-900 fw-700 ls-lg">5432</h2>
                  <h4 className="text-grey-500 d-flex justify-content-center fw-600 ls-lg font-xsssss text-uppercase">
                    <span className="mr-2 bg-instagram btn-round-xss d-inline-block mt-0 rounded-circle" />
                    Users this month
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 d-flex">
            <div className="card w-100 p-3 border-0 mt-4 rounded-lg bg-white shadow-xs overflow-hidden">
              <div className="row mt-2">
                <div className="col-4 text-center">
                  <h2 className="font-lg text-grey-900 fw-700 ls-lg">44%</h2>
                  <h4 className="text-grey-500 d-flex justify-content-center fw-600 ls-lg font-xsssss text-uppercase">
                    Revenue Growth Week
                  </h4>
                </div>
                <div className="col-4 text-center">
                  <h2 className="font-lg text-grey-900 fw-700 ls-lg">55%</h2>
                  <h4 className="text-grey-500 d-flex justify-content-center fw-600 ls-lg font-xsssss text-uppercase">
                    Revenue Growth Month
                  </h4>
                </div>
                <div className="col-4 text-center">
                  <h2 className="font-lg text-grey-900 fw-700 ls-lg">67%</h2>
                  <h4 className="text-grey-500 d-flex justify-content-center fw-600 ls-lg font-xsssss text-uppercase">
                    Revenue Growth Day
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </div>
    </div>
  );
}
