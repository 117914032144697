import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function CreateCourses(props) {
  const navigate = useNavigate();
  let isError = false;
  const [mode, setMode] = useState("create");
  const [id, setId] = useState(1);
  const [activeId, setActiveId] = useState(-1);
  const [courses, setCourses] = useState([]);
  const [courseName, setCourseName] = useState("");
  const [courseDuration, setCourseDuration] = useState("");
  const [courseDescription, setCourseDescription] = useState("");
  const [courseCost, setCourseCost] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    let fieldName = [];
    if (!courseName.length) {
      fieldName.push("Course Name");
    }
    if (!courseDuration.length) {
      fieldName.push("Course Duration");
    }
    if (!courseDescription.length) {
      fieldName.push("Course Description");
    }
    if (!courseCost.length) {
      fieldName.push("Course Cost");
    }

    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }
    if (isError) {
      return;
    }
    mode === "create" ? createCourse() : updateCourse();
  };

  const clearFields = () => {
    setCourseName("");
    setCourseDuration("");
    setCourseDescription("");
    setCourseCost("");
    setMode("create");
  };

  const createCourse = () => {
    const newCourse = {
      id: id,
      courseName: courseName,
      courseDuration: courseDuration,
      courseDescription: courseDescription,
      courseCost: courseCost,
    };

    setCourses((prevCourses) => [...prevCourses, newCourse]);

    clearFields();
    setId(id + 1);
  };

  const editCourse = (id) => {
    setMode("edit");
    setActiveId(id);
    const course = courses.find((course) => course.id === id);
    setCourseName(course.courseName);
    setCourseDuration(course.courseDuration);
    setCourseDescription(course.courseDescription);
    setCourseCost(course.courseCost);
  };

  const deleteCourse = (id) => {
    const newCourse = courses.filter((course) => course.id !== id);
    setCourses(newCourse);
  };

  const updateCourse = () => {
    const course = courses.find((course) => course.id === activeId);
    course.courseName = courseName;
    course.courseDuration = courseDuration;
    course.courseDescription = courseDescription;
    course.courseCost = courseCost;

    clearFields();
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
          Course Management
        </h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Name</label>
                <input
                  type="text"
                  name="course-name"
                  className="form-control"
                  onChange={(e) => setCourseName(e.target.value)}
                  value={courseName}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Duration</label>
                <input
                  type="text"
                  name="course-duration"
                  className="form-control"
                  onChange={(e) => setCourseDuration(e.target.value)}
                  value={courseDuration}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Description
                </label>
                <textarea
                  className="w-100 h100 style2-textarea p-3 form-control"
                  onChange={(e) => setCourseDescription(e.target.value)}
                  value={courseDescription}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Cost</label>
                <input
                  type="text"
                  name="course-name"
                  className="form-control"
                  onChange={(e) => setCourseCost(e.target.value)}
                  value={courseCost}
                />
              </div>
            </div>
            <div className="col-lg-12 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
              <Link
                className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block"
                type="submit"
                onClick={(e) => handleSubmit(e)}
              >
                {mode === "create" ? "Create" : "Edit"} Course
              </Link>
            </div>
          </div>
        </form>
      </div>
      {courses.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th className="bg-current text-white">Course ID</th>
                <th className="bg-current text-white">Course Name</th>
                <th className="bg-current text-white">Course Duration</th>
                <th className="bg-current text-white">Course Description</th>
                <th className="bg-current text-white">Course Cost</th>
                <th className="bg-current text-white">Actions</th>
              </tr>
            </thead>
            <tbody>
              {courses.map((course) => (
                <tr key={course.id}>
                  <td>{course.id}</td>
                  <td>{course.courseName}</td>
                  <td>{course.courseDuration}</td>
                  <td>{course.courseDescription}</td>
                  <td>{course.courseCost}</td>
                  <td className="text-center">
                    <i
                      className="feather-edit text-current mr-sm-2 mr-md-3 mr-lg-4"
                      style={{ cursor: "pointer" }}
                      onClick={() => editCourse(course.id)}
                    />
                    <i
                      className="feather-trash text-red"
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteCourse(course.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default CreateCourses;
