import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";

function AssignSupportTickets() {
  const navigate = useNavigate();
  const [courses] = useState([
    { value: "Operating Systems", label: "Operating Systems" },
    { value: "Networking", label: "Networking" },
    { value: "Algorithms", label: "Algorithms" },
    { value: "Compiler Design", label: "Compiler Design" },
    { value: "System Design", label: "System Design" },
  ]);

  const [coordinators] = useState([
    { value: "Chad Nicolas", label: "Chad Nicolas" },
    { value: "Noah Buckridge", label: "Noah Buckridge" },
    { value: "Sherman Herman", label: "Sherman Herman" },
    { value: "David Durgan", label: "David Durgan" },
    { value: "Hannah Dach", label: "Hannah Dach" },
    { value: "Jesus Hermiston", label: "Jesus Hermiston" },
    { value: "Douglas Hayes PhD", label: "Douglas Hayes PhD" },
    { value: "Merle Gerlach", label: "Merle Gerlach" },
  ]);
  const [supportId, setSupportId] = useState("");
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedCoordinators, setSelectedCoordinators] = useState([]);
  const [supportTickets, setSupportTickets] = useState([]);

  let isError = false;
  const [mode, setMode] = useState("assign");
  const [activeId, setActiveId] = useState(-1);

  const handleCourseSelection = (selectedValues) => {
    setSelectedCourses(selectedValues);
  };

  const handleCoordinatorselection = (selectedValues) => {
    setSelectedCoordinators(selectedValues);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let fieldName = [];
    if (!supportId.length) {
      fieldName.push("Support Id");
    }
    if (selectedCourses.length === 0) {
      fieldName.push("Selected Courses");
    }
    if (selectedCoordinators.length === 0) {
      fieldName.push("Selected Coordinators");
    }
    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }
    if (isError) {
      return;
    }
    if (mode === "assign") {
      assignSupportTickets();
    } else {
      updateAssignedSupportTickets();
    }
  };

  const assignSupportTickets = () => {
    const newSupportTicket = {
      id: supportId,
      selectedCourses: selectedCourses,
      selectedCoordinators: selectedCoordinators,
    };
    setSupportTickets((prevSupportTickets) => [
      ...prevSupportTickets,
      newSupportTicket,
    ]);
    clearFields();
  };
  const clearFields = () => {
    setSupportId("");
    setSelectedCourses([]);
    setSelectedCoordinators([]);
    setMode("assign");
  };
  const editAssignedSupportTicket = (id) => {
    setMode("edit");
    setActiveId(id);
    const supportTicket = supportTickets.find(
      (supportTicket) => supportTicket.id === id
    );
    setSupportId(supportTicket.id);
    setSelectedCourses(supportTicket.selectedCourses);
    setSelectedCoordinators(supportTicket.selectedCoordinators);
  };
  const deleteAssignedSupportTickets = (id) => {
    const newSupportTicket = supportTickets.filter(
      (supportTicket) => supportTicket.id !== id
    );
    setSupportTickets(newSupportTicket);
    clearFields();
  };
  const updateAssignedSupportTickets = () => {
    const supportTicket = supportTickets.find(
      (supportTicket) => supportTicket.id === activeId
    );
    supportTicket.id = supportId;
    supportTicket.selectedCourses = selectedCourses;
    supportTicket.selectedCoordinators = selectedCoordinators;
    clearFields();
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
          Assign Support Tickets
        </h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form action="#">
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Support Ticket ID
                </label>
                <input
                  type="text"
                  name="comment-name"
                  className="form-control"
                  onChange={(e) => setSupportId(e.target.value)}
                  value={supportId}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Course</label>
                <Select
                  isMulti={false}
                  options={courses}
                  value={selectedCourses}
                  onChange={handleCourseSelection}
                  placeholder="Select Course"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Coordinator
                </label>
                <Select
                  isMulti={false}
                  options={coordinators}
                  value={selectedCoordinators}
                  onChange={handleCoordinatorselection}
                  placeholder="Select Coordinator"
                />
              </div>
            </div>
            <div className="col-lg-6 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
              <Link
                className="bg-current text-center text-white font-xsss fw-600 p-3 w200 rounded-lg d-inline-block"
                onClick={(e) => handleSubmit(e)}
              >
                {mode === "assign" ? "Assign" : "Edit"} Support Tickets
              </Link>
            </div>
          </div>
        </form>
      </div>
      {supportTickets.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th className="bg-current text-white">Support Ticket ID</th>
                <th className="bg-current text-white">Course</th>
                <th className="bg-current text-white">Coordinator</th>
                <th className="bg-current text-white">Actions</th>
              </tr>
            </thead>
            <tbody>
              {supportTickets.map((supportTicket) => (
                <tr key={supportTicket.id}>
                  <td>{supportTicket.id}</td>
                  <td>{supportTicket.selectedCourses.value}</td>
                  <td>{supportTicket.selectedCoordinators.value}</td>
                  <td className="text-center">
                    <i
                      className="feather-edit text-current mr-sm-2 mr-md-3 mr-lg-4"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        editAssignedSupportTicket(supportTicket.id)
                      }
                    />
                    <i
                      className="feather-trash text-red"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        deleteAssignedSupportTickets(supportTicket.id)
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default AssignSupportTickets;
